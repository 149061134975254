@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --TopbarMobileMenu_topMargin: 36px;
}

/* .root {
    padding: 28px 24px 0 24px;
} */

.footer {
    display: flex;
    flex-wrap: wrap;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 24px 24px 12px 24px;
    background-color: var(--matterColorLight);

    & > svg {
        margin-bottom: 12px;

        &:first-of-type {
            margin-right: 12px;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

.avatar {
    margin: 0;
}

.greeting {
    @apply --fontSizeXXL;
    margin: 0 0 16px 0;
}

.notificationBadge {
    position: absolute;
    top: 4px;
    right: -26px;

    /* This is inside a link - remove underline */
    &:hover {
        text-decoration: none;
    }
}

.createNewListingLink {
    flex-shrink: 0;
    font-weight: 600;

    background: #8f2593;
    padding: 5px 16px;

    border-radius: 32px;

    white-space: nowrap;

    &,
    &:hover {
        color: white;
        text-decoration: none;
    }
}

.authenticationGreeting {
    /* Font */
    @apply --marketplaceH1FontStyles;

    margin-bottom: 24px;
    margin-top: var(--TopbarMobileMenu_topMargin);

    & > span:first-of-type {
        display: inline-block;
        margin-bottom: 16px;
    }
}

.loginLink,
.signupLink {
    text-decoration: none;
    white-space: nowrap;
    color: #8f2593;
}

.profileMenuContentMob {
    width: calc(100% + 48px);
    margin: 0 -24px;

    & > li {
        padding: 8px 24px;
    }

    &.loggedIn {
        & > li {
            &:last-of-type {
                padding: 8px 0;
            }
        }
    }

    /* @media (--viewportMediumMax) {
        padding-bottom: 100px;
    } */
}

.topBarMobTopSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    column-gap: 24px;

    margin: var(--TopbarMobileMenu_topMargin) 0 16px 0;
}

.topBarMenuAvailabilityHolder {
    display: inline-block;

    & .topBarAvailabilityBadge {
        flex-wrap: nowrap;
        white-space: nowrap;

        width: auto;

        padding: 4px 8px;

        margin: 0;
        background: none;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }

    & .availability-notAvailable {
        color: #f00;
    }

    & .availability-confirmed,
    & .availability-notConfirmed {
        color: #1a2b49;
    }
}
