@import './marketplaceFonts.css';

/* ================ Custom properties aka variables ================ */

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
    /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */
    /* Full screen Background image located in root-folder/src/assets */
    --backgroundImage: {
        /* Gradient direction and overlaying the black color on top of the image for better readability */
        background: linear-gradient(-45deg, #00000063, #00000063),
            url('https://horsedeal.imgix.net/static/hero-image.png');

        /* Add loading color for the div */
        background-color: var(--matterColor);

        /* Cover the whole screen with the background image */
        background-size: cover;

        /* Align the image within the container */
        background-position: center center;

        @media (--viewportLarge) {
            border-radius: 40px;
            border: solid 36px var(--matterColorBright);
        }
    }

    /* ================ Colors ================ */
    --marketplaceColor: #8f2593;
    --marketplaceColorFade: #f9f4fa;
    --errorColorLight: #ff4c38;
    --marketplaceColorDark: #76007b;
    /* --marketplaceColorDark: #88298b; */
    --marketplaceColorPurple: #923395;
    --marketplaceColorDarkMedium: #1a2b49;
    --marketPlaceColorLightSecondary: #e0e0e0;
    --marketPlaceColorSubTitleLandingPage: rgb(26, 43, 73);
    /* Used with inline CSS SVGs */
    --marketplaceColorEncoded: %23c0392b;
    --marketplaceLinearGradientMainColor: linear-gradient(
        135deg,
        rgba(210, 170, 225, 1) 0%,
        rgba(191, 120, 215, 1) 27%,
        rgba(165, 77, 175, 1) 100%
    );
    --successColor: #1e9f55;
    --successColorDark: #239954;
    --successColorLight: #f0fff6;
    --failColor: #ff0000;
    --failColorLight: #fff0f0;
    --attentionColor: #ffaa00;
    --attentionColorLight: #fff7f0;
    --bannedColorLight: var(--errorColorLight);
    --bannedColorDark: var(--marketplaceColor);
    --matterColorDark: #1a2b49;
    --matterColor: #1a2b49;
    --matterColorAnti: #b2b2b2;
    --matterColorAntiSecondary: #9c9c9c;
    --matterColorAntiSoft: #dddddd;
    --matterColorNegative: #e6e6e6;
    --matterColorNegativeSoft: #cdcdcd;
    --matterColorCrossed: #bababa;
    --matterColorBright: #ffffff;
    --matterColorLight: #ffffff;
    --productNotificationColor: #006a6f;
    --matterColorLightTransparent: rgba(255, 255, 255, 0.65);
    --matterColorActive: #f4f4f6;
    --colorfulPrimary: #1877f2;
    /* ================ Spacing unites ================ */
    /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
    --spacingUnit: 6px;
    --spacingUnitDesktop: 8px;
    /* Shadows */
    --boxShadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
    --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
    --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    --boxShadowFilterButtonEnhanced: 0px 0px 20px 0px rgba(0, 0, 0, 0.1),
        0px 4px 4px rgba(0, 0, 0, 0.25);
    /* ================ Z-index base levels ================ */
    /* topbar is positioned slightly above content */
    --zIndexTopbar: 55;
    /* small popups on UI should use z-indexes above 50 */
    --zIndexPopup: 50;
    /* modals and UI overlays should use z-indexes above 100 */
    --zIndexModal: 100;
    /* generic error message should overlay even modals */
    --zIndexGenericError: 200;
    /* ================ Border radius ================ */
    --borderRadius: 5px;
    --borderRadiusMobileSearch: 3px;
    /* ================ Transition styles ================ */
    --transitionStyle: ease-in 0.2s;
    --transitionStyleButton: ease-in-out 0.1s;
    /* ================ Topbar heights ================ */
    --topbarHeight: 60px;
    --topbarHeightDesktop: 74px;
    --topbarHeightEmailVerificationNote: 60px;
    /* ================ Modal default padding ================ */
    --modalPadding: 24px;
    --modalPaddingMedium: 40px;
    /* ================ LocationAutocompleteInput bottom attribution padding ================ */
    /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
    --locationAutocompleteBottomPadding: 72px;

    --transactionItemMaxWidth: 550px;
    --mobile-search-panel-max-width: 480px;
    --fontsCommon {
        letter-spacing: -0.25px;
        font-weight: 400;
    }

    --fontSizeXS {
        /** 14-20 case */
        @apply --fontsCommon;
        font-size: 14px;
        line-height: 20px;
    }

    --fontSizeSM {
        /** 16-24-case */
        @apply --fontsCommon;
        font-size: 16px;
        line-height: 24px;
    }

    --fontSizeMM {
        /** 18-28 case */
        @apply --fontsCommon;
        font-size: 18px;
        line-height: 28px;
    }

    --fontSizeM {
        /** 20-30-case */
        @apply --fontsCommon;
        font-size: 20px;
        line-height: 30px;
    }

    --fontSizeXL {
        /** 24-32-case */
        @apply --fontsCommon;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5px;
    }

    --fontSizeXXL {
        /** 32-40 case */
        letter-spacing: -0.5px;
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
    }

    --fontSizeXXXL {
        /** 40-48 case */
        letter-spacing: -0.5px;
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
    }

    --paragraphAntiSM {
        @apply --fontSizeXS;
        color: var(--matterColorAnti);
    }

    --paragraphAntiMedium {
        @apply --paragraphAntiSM;
        @apply --fontSizeMM;
    }

    --flexCentered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    --roundedBadgeWithIconInside {
        position: absolute;
        left: calc(50% - 20px);
        top: -20px;
        z-index: 1;

        /* use --flexCentered */
        display: flex;
        align-items: center;
        justify-content: center;

        background: #fff;
        border-radius: 50%;
        border: 1px solid var(--matterColorNegative);

        & path {
            fill: var(--marketplaceColor);
        }
    }

    --roundedBadgeWithIconInsideS {
        @apply --roundedBadgeWithIconInside;
        width: 28px;
        height: 28px;
    }

    --roundedBadgeWithIconInsideM {
        @apply --roundedBadgeWithIconInside;
        width: 40px;
        height: 40px;
    }

    --authSubsection {
        @apply --paragraphAntiSM;
        display: inline-block;
        text-align: left;
    }

    --heading {
        color: var(--matterColorAnti);
        @apply --fontSizeXL;
        margin: 36px 0 12px 0;

        font-weight: 600;

        @media (--viewportMedium) {
            margin: 59px 0 27px 0;
        }
    }

    --checkoutFormLabel {
        @apply --fontSizeXL;
        font-weight: 600;
        color: var(--matterColorAnti);
    }

    --inputFieldPrimary {
        height: 38px;
        flex-grow: 1;

        font-size: 16px;
        text-overflow: ellipsis;
        border: none;

        margin: 0;
        padding: 5px 42px 5px 16px;
    }

    --inputFieldWrapper {
        position: relative;

        width: 100%;
        border: 1px solid #e6e6e6;
        height: 42px;
        border-radius: 40px;
        background-color: white;

        & input {
            @apply --inputFieldPrimary;
        }
    }

    --inputFieldIconHolder {
        height: 34px;
        width: 34px;
        min-width: unset;
        position: absolute;
        padding: 0;
        right: 4px;
        top: 3px;
        background-color: var(--marketplaceColor);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        pointer-events: none;

        & svg {
            margin-bottom: -1px;
            margin-right: -1px;
        }
    }

    --customFieldHolder {
        border: 1px solid #e6e6e6;
        /** disciplines */
        /* border-top: none; */

        position: relative;

        display: flex;
        align-items: center;

        padding: 16px;

        & > svg {
            margin-right: 12px;
        }

        & > div {
            /** input root class name **/
            width: 100%;
            & svg {
                /**  class name for delete icon inside input **/
                top: 0;
                right: 0;
            }
        }

        /** input error class name **/

        & [data-type='error-message'] {
            position: absolute;
            bottom: -50px;
            left: 0;
        }

        & input {
            border: none;
            width: 100%;
            padding: 0;
            height: auto;

            &:focus {
                padding: 0;
            }

            & + span {
                display: none;
            }
        }
    }
    /* ================ Tooltips ================ */

    --tooltipContent {
        & p {
            @apply --fontSizeXS;

            text-align: left;
            color: var(--matterColorDark);

            margin-bottom: 0;

            &:nth-of-type(1) {
                margin-bottom: 10px;
                font-weight: 600;
            }
        }
    }

    --tooltipStaticCloseIcon {
        z-index: 1;
        pointer-events: auto;

        position: absolute;
        right: 16px;
        top: 16px;
        width: 16px;
        height: 16px;

        margin: 0;

        cursor: pointer;

        & path {
            fill: #1a2b49;
        }
    }

    /* ================ SearchPage filters ================ */
    --marketplaceFilterLabel {
        padding: 8px 16px;
        width: auto;
        height: auto;
        min-height: 0;
        border-radius: 99px;
    }

    --marketplaceFilterLabelFocused {
        outline: none;
        background-color: var(--matterColorLight);
        border-color: var(--matterColor);
        text-decoration: none;
        /* box-shadow: var(--boxShadowFilterButton); */
    }

    --marketplaceFilterPopup {
        /* By default hide the content */
        display: block;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;

        /* Position */
        position: absolute;
        z-index: var(--zIndexPopup);

        /* Layout */
        min-width: 300px;
        margin-top: 7px;
        background-color: var(--matterColorLight);

        /* Borders */
        border-top: 1px solid var(--matterColorNegative);
        box-shadow: var(--boxShadowFilterButtonEnhanced);
        border-radius: 15px;
        transition: var(--transitionStyleButton);
    }

    --marketplaceFilterClearButton {
        /* @apply --marketplaceH5FontStyles; */
        @apply --fontSizeXS;
        font-weight: 600;

        color: var(--matterColor);

        /* Layout */
        display: inline;
        float: right;
        padding: 0;
        margin: 0;
        /* Override button styles */
        outline: none;
        text-align: left;
        border: none;
        text-decoration-line: underline;

        &:focus,
        &:hover {
            color: var(--matterColor);
            cursor: pointer;
        }

        &:disabled {
            &,
            &:hover {
                color: #b2b2b2;
                cursor: auto;
            }
        }
    }

    --marketplaceFilterLabelLine {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 0;
    }

    --marketplaceFilterLabelButton {
        /* Override button styles */
        outline: none;
        text-align: left;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    /* ================ ui ================ */

    --separatorLine {
        border-top: 1px solid #e4e4e4;
        margin-left: -30px;
        margin-right: -30px;
    }

    --dot {
        &:after {
            content: '.';
            display: block;
            position: absolute;
            right: 0;
            top: -20%;
        }
    }

    --disabled {
        opacity: 0.5;
        background-color: #ffffff !important;
        color: grey !important;
    }

    /* ================ Clearfix solution ================ */
    --clearfix {
        &::after {
            content: '';
            display: table;
            clear: both;
        }
    }

    --marketPlaceTextOverflow {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    /* ================ Links ================ */
    --marketplaceLinkStyles: {
        /* Position and dimensions */
        display: inline;
        margin: 0;
        padding: 0;

        /* Borders */
        border: none;

        /* Colors */
        color: var(--marketplaceColor);

        /* Text size should be inherited */
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    /* ========== Carousel ========== */
    --marketplaceCarouselModalScrollLayer {
        width: 100vw;
        height: 100vh;
        background-color: rgba(1, 1, 1, 0.92);
    }

    --marketplaceCarouselModalContainer {
        width: 100vw;
        height: 100vh;
        background-color: rgba(1, 1, 1, 0.92);
    }

    /* ================ Buttons ================ */
    --marketplaceButtonFontStyles: {
        font-family: 'sofiapro', Helvetica, Arial, sans-serif;
        font-weight: var(--fontWeightSemiBold);
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;

        @media (--viewportMedium) {
            /* TODO: Make sure button text aligns with the baseline */
        }
    }

    --marketplaceEditListingSubmitButton {
        margin-top: 35px;
        margin-bottom: 24px;
        flex-shrink: 0;

        & svg {
            transform: rotate(180deg) translateY(6px);
            & path {
                fill: white;
                stroke: white;
            }
        }

        @media (--viewportLarge) {
            display: inline-block;
            width: 756px;
            margin-top: 80px;
        }
    }

    --marketplaceEditListingFieldItem {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.0888889px;

        & input,
        & textarea,
        & select {
            &::placeholder {
                font-weight: 100;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: -0.0888889px;
                color: var(--matterColorAnti);
            }
        }

        & label {
            font-style: normal;
            /* font-weight: 600; */
            font-size: 17px;
            line-height: 20px;
            color: var(--matterColor);
        }
    }

    --marketplaceButtonDisabledStyles {
        background-color: var(--matterColorNegative);
        color: var(--matterColorAnti);
        cursor: not-allowed;
        box-shadow: none;
    }

    --marketplaceButtonStyles {
        @apply --marketplaceButtonFontStyles;

        /* Dimensions */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 60px;
        margin: 0;

        /* Borders */
        border: none;
        border-radius: var(--borderRadius);

        /* Colors */
        background-color: var(--marketplaceColor);
        color: var(--matterColorLight);

        /* Text */
        text-align: center;
        text-decoration: none;

        /* Effects */
        transition: all var(--transitionStyleButton);
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            background-color: var(--marketplaceColorDark);
            text-decoration: none;
            /* box-shadow: var(--boxShadowButton); */
        }

        &:disabled {
            @apply --marketplaceButtonDisabledStyles;
        }

        @media (--viewportMedium) {
            min-height: 64px;
        }
    }

    --marketplaceFilterButtonStyle {
        background-color: var(--matterColorActive);
        color: inherit;

        &:hover,
        &:focus {
            outline: none;
            background-color: var(--matterColorActive);
            text-decoration: none;
        }
    }

    --marketplaceGradientButton {
        background-image: var(--marketplaceLinearGradientMainColor);
    }
    --marketplaceButtonStylesPrimary {
        @apply --marketplaceButtonStyles;

        background-color: var(--successColor);
        color: var(--matterColorLight);

        &:hover,
        &:focus {
            background-color: var(--successColorDark);
            box-shadow: var(--boxShadowButton);
        }

        &:disabled {
            background-color: var(--matterColorNegative);
            color: var(--matterColorAnti);
        }

        &:disabled:hover {
            background-color: var(--matterColorNegative);
            color: var(--matterColorAnti);
            box-shadow: none;
        }
    }
    --marketplaceButtonStylesSecondary {
        @apply --marketplaceButtonStyles;

        background-color: var(--matterColorLight);
        color: var(--matterColor);

        /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
        padding: 18px 0 0 0;

        border-width: 1px;
        border-style: solid;
        border-color: var(--matterColorNegative);

        &:hover,
        &:focus {
            background-color: var(--matterColorLight);
            /* border-color: var(--matterColorAnti); */
            border-color: var(--matterColor);
        }

        &:disabled {
            background-color: #fff;
            color: var(--matterColorAnti);
            border-color: var(--matterColorNegative);
        }
    }

    /* ================ Inputs ================ */
    --marketplaceInputStyles: {
        /* Dimensions */
        display: block;
        width: 100%;
        margin: 0;
        padding: 5px 0 5px 0;
        color: var(--matterColor);
        /* Borders */
        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: var(--marketplaceColorDarkMedium);

        @apply --fontSizeSM;

        &::placeholder {
            color: var(--matterColorAnti);
        }

        /* Effects */

        transition: border-bottom-color var(--transitionStyle);

        &:hover,
        &:focus {
            border-bottom-color: var(--matterColor);
            outline: none;
        }

        @media (--viewportMedium) {
            padding: 4px 0 10px 0;

            @apply --fontSizeMM;
        }
    }
    --marketplaceSelectStyles: {
        /* Dimensions */
        display: block;
        width: 100%;
        margin: 0;

        /* Unset user agent styles */
        appearance: none;

        /* Borders */
        border: none;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: var(--attentionColor);
        border-radius: 0;

        /* Background */
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
        background-size: 16px 16px;
        background-position: center right;

        /* Effects */

        cursor: pointer;
        transition: border-bottom-color var(--transitionStyle);

        &:hover,
        &:focus {
            border-bottom-color: var(--matterColor);
            outline: none;
        }
    }

    /* ================ Modals ================ */
    --marketplaceModalRootStyles {
        /* Page is using flex: AuthenticationPage's .root takes all available space */
        flex-grow: 1;

        /* AuthenticationPage's root uses flexbox */
        display: flex;

        @media (--viewportMedium) {
            justify-content: center;
            align-items: flex-start;
        }
    }
    --marketplaceModalFormRootStyles {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        height: 100%;
    }
    --marketplaceModalBaseStylesCore {
        flex-grow: 1;

        /* Create context so the close button can be positioned within it */
        position: relative;

        /* Display and dimensions */
        display: flex;
        flex-direction: column;
        padding: 24px;

        background-color: var(--matterColorLight);
        border-radius: var(--borderRadius);
        border-bottom: none;
    }
    --marketplaceModalBaseStylesDesktop {
        flex-basis: 480px;
        flex-grow: 0;

        margin-top: 144px;
        margin-bottom: 12.5vh;
    }
    --marketplaceModalBaseStyles {
        @apply --marketplaceModalBaseStylesCore;

        @media (--viewportMedium) {
            @apply --marketplaceModalBaseStylesDesktop;
        }
    }
    --marketplaceModalResetStyles {
        border-top: none;
        background-color: unset;
    }
    --marketplaceSubheadingSectionStyles {
        @apply --paragraphAntiSM;
        font-size: 16px;
        line-height: 32px;

        @media (--viewportMedium) {
            font-size: 20px;
        }
    }
    --subHeading {
        @apply (--marketplaceSubheadingSectionStyles);
        margin-bottom: 16px;

        & svg {
            width: 16px;
            height: 16px;
            margin: auto 5px 5px auto;

            transform: rotate(180deg);

            & path {
                fill: var(--matterColorDark);
                stroke: var(--matterColorDark);
            }
        }
    }
    --marketplaceModalInMobileBaseStyles {
        flex-grow: 1;

        /* Create context so the close button can be positioned within it */
        position: relative;

        /* Display and dimensions */
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 0;

        background-color: var(--matterColorLight);
        border-radius: var(--borderRadius);

        @media (--viewportMedium) {
            flex-basis: 480px;
            flex-grow: 0;
            height: 100%;
            padding: 0;
            background-color: transparent;
            border-radius: 0;
        }
    }
    /** ADD TO COLLECTING INFO */
    --formFieldsItem {
        position: relative;

        &:first-of-type {
            border-radius: 4px 4px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 4px 4px;
            border-bottom: 1px solid #e6e6e6;
        }

        & label {
            @apply --fontSizeMM;

            width: 100%;
            padding: 16px;

            &:hover {
                cursor: pointer;
            }
        }

        transition: 0.35s;

        border: 1px solid #e6e6e6;
        border-bottom-color: transparent;
        background: white;

        &:hover {
            background: #f9f9f9;
            border: 1px solid #b2b2b2;
        }
    }

    --formFieldsItem-selected {
        background: var(--marketplaceColorFade);
        border: 1px solid var(--marketplaceColor);

        &:last-of-type {
            border: 1px solid var(--marketplaceColor);
        }

        &:hover {
            background: var(--marketplaceColorFade);
            border: 1px solid var(--marketplaceColor);
        }

        & + .selected {
            border-top: none;
        }
    }

    --loadingOverlay {
        filter: blur(5px);
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
        }
    }

    --documentsSection {
        margin-top: 24px;

        & > div {
            background: #f9f9f9;
            color: var(--marketplaceColor);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            padding: 16px 64px 16px 16px;
            border-radius: 4px;

            position: relative;

            & > span {
                position: absolute;
                cursor: pointer;
                top: calc(50% - 16px);
                right: 16px;
            }

            & svg {
                fill: none;
            }
        }
    }

    /* Modal title */
    --marketplaceModalTitleStyles {
        line-height: 40px;
        letter-spacing: -0.5px;
        margin: 0;
        font-weight: var(--fontWeightBold);
        font-size: 32px;
        line-height: 40px;
    }

    /* Modal message */
    --marketplaceModalParagraphStyles {
        margin: 16px 0 48px 0;
        font-size: 20px;
        line-height: 30px;
    }

    /* Bottom wrapper for the modal */
    --marketplaceModalBottomWrapper {
        text-align: center;
        margin-top: 60px;
        align-self: stretch;

        @media (--viewportMedium) {
            margin-top: 96px;
        }
    }

    /* Adjust the bottomWrapper's margin to match modals without the helper text */
    --marketplaceModalBottomWrapperText {
        margin-top: 24px;
        padding-bottom: 8px;

        letter-spacing: -0.25px;

        @media (--viewportMedium) {
            padding-bottom: 0;
        }
    }
    --marketplaceModalHelperText {
        @apply --marketplaceH5FontStyles;
        color: var(--matterColorAnti);
        margin: 0;

        @media (--viewportMedium) {
            margin: 0;
        }
    }
    --marketplaceModalHelperLink {
        @apply --marketplaceH5FontStyles;
        color: var(--matterColor);
        margin: 0;

        @media (--viewportMedium) {
            margin: 0;
        }
    }

    /* Arrow icon */
    --withArrowIcon {
        position: relative;

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 2px;
            height: 8px;
            background-color: white;
            right: calc(-50% + 2px);
            top: 49.5%;
            border-radius: 5px;
        }

        &:after {
            transform: rotate(45deg) translateY(3px) translateX(-2.5px);
        }

        &:before {
            transform: rotate(-45deg) translateY(-6px);
        }
    }

    --withArrowIconUp {
        &:before {
            transform: rotate(45deg) translateY(-1px);
        }
        &:after {
            transform: rotate(-223deg) translateY(-3px) translateX(-4px);
        }
    }
    --marketplaceModalBtnMainStyle {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        width: 100%;
        height: 64px;
        padding: 0;
        border: none;
        color: white;
        background-color: var(--marketplaceColor);
        transition: all 0.1s ease-in-out;
        font-weight: 600;
        border-radius: 4px;
        cursor: pointer;
        outline: none;
        &:hover,
        &:focus {
            /* background-color: var(--marketplaceColorDark); */
            /* box-shadow: var(--boxShadowButton); */
        }
        & > span {
            font-size: 18px;
            line-height: 24px;
        }

        @media (--viewportLarge) {
            margin-top: 32px;
            min-width: 312px;

            & > span {
                font-size: 20px;
                line-height: 28px;
            }
        }
        & > svg {
            fill: none;
            margin-right: -25px;
            margin-left: 20px;
        }
    }

    --marketplaceNotActiveBtnStyles {
        background-color: var(--matterColorNegative);
        text-decoration: none !important;
        &:hover,
        &:focus {
            background-color: var(--matterColorNegative);
            text-decoration: none !important;
            box-shadow: none;
        }
    }
    /** this might be moved to the component styles */
    --marketplaceGradientLine {
        height: 66px;
        position: absolute;
        bottom: 60px;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 93.09%);

        @media (--viewportSmallMax) {
            bottom: 58px;
        }
    }

    --fieldset {
        @apply --paragraphAntiSM;
        font-size: 18px;
        line-height: 28px;

        position: absolute;
        left: 16px;
        top: 15px;

        pointer-events: none;
        transition: 0.25s;
        padding: 0;
    }

    --fieldsetVisible {
        top: 0;
        pointer-events: initial;
        font-size: 14px;
    }
    --inputRemoveValueIcon {
        fill: none;
        position: absolute;
        right: 24px;
        top: 16px;
        cursor: pointer;
    }
    --inputSuccess {
        border-bottom-color: var(--successColor);

        & + .fieldset {
            color: var(--successColor);
        }
        &:focus,
        &:hover {
            border-bottom-color: var(--successColor);
        }

        &:focus {
            & + .fieldset {
                color: var(--successColor);
            }
        }
    }
    --inputError {
        border-bottom-color: var(--failColor);

        &:focus,
        &:hover {
            border-bottom-color: var(--failColor);
        }

        &:focus,
        &.filledInput {
            & + .fieldset {
                color: var(--failColor);
            }
        }
    }
    --inputFocused {
        border-bottom-color: var(--marketplaceColorDark);

        & + .fieldset {
            @apply --fieldsetVisible;

            color: var(--marketplaceColorDark);
        }

        &:focus,
        &:hover {
            border-bottom-color: var(--marketplaceColorDark);
        }

        &:focus {
            & + .fieldset {
                color: var(--marketplaceColorDark);
            }
        }
    }
    --marketplaceToggleTextBtn {
        @apply --marketplaceButtonStylesSecondary;
        @apply --marketplaceSearchFilterLabelFontStyles;
        /* @apply --withArrowIcon; */

        border: 1px solid #b2b2b2;
        transition: 0s;

        @apply --fontSizeXS;

        &:after,
        &:before {
            right: 25px;
            top: 44%;
            background-color: var(--matterColor);
        }

        /* @apply --withArrowIconDown; */

        margin: 20px 0 0 0;
        padding: 11px 0;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        height: auto;
        min-height: 0;

        border-radius: 30px;
        font-weight: 400;

        /* text-align: center; */
        padding-left: 0;

        &:hover {
            box-shadow: none;
        }
        &:focus {
            outline: none;
            background-color: var(--matterColorLight);
            text-decoration: none;
            box-shadow: none;
        }
        @media (--viewportMedium) {
            justify-content: flex-start;

            width: 155px;
            /* text-align: left; */
            padding-left: 22px;
        }
    }
    /*  choose image form field */
    /** this might be moved to the component styles */
    --marketplaceChooseImageText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    --marketplaceChooseImage {
        @apply --marketplaceH4FontStyles;
        color: var(--matterColor);
        font-weight: var(--fontWeightSemiBold);

        margin-top: 0;
        margin-bottom: 0;

        @media (--viewportLarge) {
            font-weight: var(--fontWeightSemiBold);
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    --marketplaceImageTypes {
        @apply --marketplaceTinyFontStyles;
        color: var(--matterColorAnti);

        margin-top: 0;
        margin-bottom: 0;

        @media (--viewportLarge) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    /* Modal form classes */
    --modalContent {
        display: flex;
        flex-direction: column;
        height: 100%;

        & button {
            @media (--viewportSmallMax) {
                margin-top: auto;
            }
        }
    }

    /* Modal Close icon */
    --marketplaceModalCloseStyles {
        /* Position inside modal */
        position: fixed;
        top: 0;
        right: 0;

        /* Some content (like map) is positioning itself on top of close button without z-index handling */
        z-index: calc(var(--zIndexModal) - 1);

        /* Safari didn't know how to baseline-align svg icon and text */
        display: flex;
        align-items: center;
        width: auto;

        /* Sizing (touch area) */
        padding: 16px;
        margin: 0;
        border: 0;

        @media (--viewportMedium) {
            position: absolute;
        }

        /* Colors */
        background-color: transparent;
        color: var(--matterColor);

        cursor: pointer;

        /* &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }

    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    } */

        &:disabled {
            background-color: transparent;
        }
    }
    --marketplaceModalIconStyles {
        margin-bottom: 16px;
        fill: none;
    }
    --marketplaceModalCloseText {
        margin: -2.5px 0 0 0;

        @apply --fontSizeSM;
        text-transform: capitalize;

        @media (--viewportMedium) {
            margin-top: -1px;
            @apply --fontSizeMM;
        }
    }
    --marketplaceModalCloseIcon {
        display: inline-block;
        margin-left: 8px;
        padding: 0;
        box-sizing: content-box;
    }
    --marketplaceModalErrorStyles {
        @apply --marketplaceH4FontStyles;
        margin-top: 24px;
        color: var(--failColor);
    }
    --marketplaceModalHighlightEmail {
        font-weight: var(--fontWeightBold);
    }
    --marketplaceModalPasswordMargins {
        /* Leave space between the input and the button below when the
    viewport height is small */
        margin-top: 24px;

        @media (--viewportMedium) {
            margin-top: 32px;
        }
    }
    --modalCarouselScrollLayer {
        width: 100vw;
        height: 100vh;
        background-color: rgba(1, 1, 1, 0.92);
    }
    --modalCarouselContainer {
        @media (--viewportSmallMax) {
            background-color: transparent;
        }
    }
    --modalNoBorderContainer {
        border: none;
        /* margin-top: 48px; */
        padding: 24px;

        @media (--viewportMedium) {
            margin-top: 144px;
        }
    }
    --modalStickyFooter {
        border-top: none;
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;

        background: white;

        padding: 24px;
        margin: 0;

        z-index: 1;

        @media (--viewportMedium) {
            position: sticky;
            bottom: 0px;

            margin: 0 -24px 0 -24px;
            padding: 24px;

            border-radius: 0 0 8px 8px;
        }
    }

    --modalWithStickyFooter {
        padding: 0;

        /* @media (--viewportSmallMax) {
            padding-top: 56px;
        } */

        &[datatype='scroll-position-unset'],
        &[datatype='scroll-position-top'],
        &[datatype='scroll-position-middle'],
        &[datatype='scroll-position-bottom'] {
            & footer {
                @apply --modalStickyFooter;
            }

            & > button:first-of-type {
                width: 100%;
                justify-content: flex-end;
                background: white;
                border-radius: 8px 8px 0 0;
            }
        }

        &[datatype='scroll-position-top'] footer,
        &[datatype='scroll-position-middle'] footer {
            border-top: 1px solid #e6e6e6;
        }
    }

    --dotGroup {
        display: flex;
        justify-content: center;

        @media (--viewportMedium) {
            position: relative;
            top: 25px;
        }

        & button {
            border: none;
            height: 8px;
            width: 8px;
            display: inline-block;
            border-radius: 50%;
            padding: 0;
            background: #b2b2b2;
            margin-left: 4px;
            opacity: 0.5;

            &:nth-of-type(1) {
                margin-left: 0;
            }

            &:disabled {
                height: 10px;
                width: 10px;
                opacity: 1;
            }
        }

        @media (--viewportSmallMax) {
            margin-top: 16px;
        }
    }

    --sliderButtonDisabled {
        & path {
            stroke: #b2b2b2;
            fill: #b2b2b2;
        }

        &:hover {
            cursor: not-allowed;
        }
    }

    --sliderButton {
        height: 48px;
        width: 48px;

        background: #ffffff;
        border: 1px solid #e6e6e6;

        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        & path {
            stroke: var(--matterColorDark);
            fill: var(--matterColorDark);
        }

        transition: 0.35s;

        &:hover:not(&:disabled) {
            border: 1px solid;
        }

        &:disabled {
            @apply --sliderButtonDisabled;
        }
    }

    --sliderButtonBack {
        left: 0;
        margin-right: 16px;
        & svg {
            transform: rotate(180deg) scale(1.15);
        }
    }

    --sliderButtonNext {
        right: 0;

        & svg {
            transform: scale(1.15);
        }
    }

    --locationPredictionsPrimary {
        position: absolute;
        width: 100%;
        top: 60px;
        left: 0;

        background-color: white;

        box-shadow: var(--boxShadow);
        border-radius: 0 0 4px 4px;
        z-index: calc(var(--zIndexPopup) + 1);

        & ul {
            padding: 6px 0;
        }
    }
    --autocompleteDefaultItem {
        padding: 8px 16px;
        margin: 0;

        &:hover {
            cursor: pointer;
            background-color: #f2f2f2;
        }

        @media (--viewportSmallMax) {
            @apply --fontSizeSM;
        }
    }
    --scrollBarStyled {
        &::-webkit-scrollbar {
            width: 6px; /* width of the entire scrollbar */
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e6e6e6; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: 3px solid #e6e6e6; /* creates padding around scroll thumb */
        }
    }

    --notificationDot {
        /* Dimensions */
        width: 7px;
        height: 7px;
        border-radius: 50%;

        /* Position */
        position: absolute;
        top: 0px;
        right: -2px;

        /* Style: red dot */
        background-color: var(--failColor);

        z-index: 1;
        opacity: 1;
        transform: scale(1);
    }

    --badge {
        @apply --fontSizeSM;
        font-weight: 700;
        padding: 4px 8px;

        border-radius: 48px;
        border: 1px solid var(--matterColorDark);
        background-color: #fff;

        display: inline-block;

        &:hover {
            cursor: pointer;
        }
    }

    --badge_selected {
        color: var(--marketplaceColor);
        border: 2px solid var(--marketplaceColor);
        background: var(--marketplaceColorFade);
    }

    --listing_card_wrapper {
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
        border-radius: 8px;
        transition: 0.3s;
        /*
        * force e.g. video to have the same border-radius as card
        */
        overflow: hidden;

        &:hover {
            text-decoration: none;
            cursor: pointer;
            box-shadow: 0px 0px 14px 8px rgb(0 0 0 / 5%);
        }
    }

    --marketplaceDivider {
        content: '';
        width: 42%;
        border-bottom: solid 1px #d2d2d2;
        position: absolute;
        top: 50%;
        height: 1px;
        z-index: 1;
    }
    /*
        *  e.g. --------------- order -----------------
        */
    --delimiterWithMiddleText {
        width: 100%;
        margin: 32px 0;
        text-align: center;
        position: relative;

        &::after {
            @apply --marketplaceDivider;
            left: 0;
        }

        &::before {
            @apply --marketplaceDivider;
            right: 0;
        }

        & + div {
            margin-bottom: 10px;
        }
        & > span {
            background-color: unset;
            color: var(--matterColorAnti);
            font-size: 18px;
            width: auto;
            display: inline-block;
            z-index: 3;
            padding: 0 20px 0 20px;
            position: relative;
            margin: 0;
        }
    }

    --radionDropDownListItem {
        border-radius: 0;
        border: none;
        width: 100%;

        background: unset;

        &:hover {
            background: #f2f2f2;
            border: none;
        }

        & label {
            padding: 8px 16px;

            & svg {
                & circle:first-of-type {
                    stroke-width: 1.5px;
                }
            }
        }

        & input {
            &:checked {
                & + label {
                    /* selected  */
                    background: unset;

                    & svg {
                        & circle:first-of-type {
                            stroke-width: 2px;
                        }
                    }

                    &:hover {
                        /* selected + hovered */
                        background: #f2f2f2;
                    }
                }
            }
        }
    }
}

/* ================ Custom media queries ================ */
@custom-media --viewportMiniSmall (min-width: 320px);
@custom-media --viewportSmall (min-width: 425px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);

@custom-media --viewportMediumMax (max-width: 1024px);
@custom-media --viewportSmallMax (max-width: 768px);
@custom-media --viewportMiniSmallMax (max-width: 410px);

/* Customize CSS Code */
