@import '../../marketplace.css';

.root {
    width: 100%;
    max-width: 296px;
    border: 1px solid #e6e6e6;
    height: 42px;
    border-radius: 40px;
    background-color: white;
}

.searchInputIcon {
    position: absolute;
    border: none;
    left: 12px;
    padding: 0;
    top: calc(50% - 16px);
    background: none;
}

input.searchInput {
    font-size: 16px;

    border: none;
    height: 38px;

    flex-grow: 1;

    padding: 5px 0 5px 16px;
    margin: 0;

    &:hover,
    &:focus {
        outline: none;
        padding: 5px 0 5px 16px;
        &::placeholder {
            color: var(--matterColor);
        }
    }

    line-height: unset;
    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
        line-height: normal;
    }

    /* &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  } */

    &::placeholder {
        text-overflow: ellipsis;
        overflow-x: hidden;
        font-weight: var(--fontWeightRegular);
        transition: var(--transitionStyleButton);
    }
}

.searchPredictions {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: var(--marketplaceColor);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);

    /* margin-top: 16px; */
    margin-bottom: 102px;

    @media (--viewportXLarge) {
        margin-bottom: 0;
    }

    & span,
    & li {
        color: rgba(255, 255, 255, 0.75);

        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }

    & li {
        &:hover {
            border-left: 5px solid white;
        }
    }
}
