@import '../../marketplace.css';

:root {
    --firstColumn: {
        width: 48%;

        @media (--viewportSmallMax) {
            width: 55%;
        }
    }

    --secondColumn: {
        width: 52%;

        @media (--viewportSmallMax) {
            width: 45%;
        }
    }
}

.modalContainer {
    border: none;

    /* for overlays, e.g. disciplines */
    position: relative;

    height: auto;
    min-height: auto;

    padding: 0;

    /* close button  */
    & > button:first-of-type {
        display: flex;
        align-items: center;
    }

    @media (--viewportSmallMax) {
        & input,
        & select,
        & textarea,
        & input + span,
        & textarea + span {
            @apply --fontSizeSM;
        }
    }
}

.modalContent {
    max-height: 720px;
    overflow: auto;

    @media (--viewportSmallMax) {
        max-height: unset;

        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

.editorHeading {
    margin-top: 0;

    @media (--viewportSmallMax) {
        margin-top: 0;
        font-size: 24px;
        line-height: 32px;
    }

    @media (--viewportMedium) {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.5px;
    }
}

.editorDesc {
    margin: 0 0 32px 0;

    @media (--viewportSmallMax) {
        font-size: 16px;
        line-height: 24px;
    }
}

.userDataProtectedMessage {
    @apply --paragraphAntiSM;

    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        width: 16px;
        height: 16px;
        fill: none;

        margin-right: 4px;
    }

    @media (--viewportSmallMax) {
        margin-top: auto;
    }
}

.editUserExperinceEditSection {
    margin-bottom: 24px;
}

.editUserAgeDataProtectedMessage {
    @media (--viewportMedium) {
        margin-top: 40px;
        margin-bottom: 0;
    }
}

.holder {
    @apply --formFieldsItem;

    & > *:not(code) {
        & label {
            padding: 0;

            display: flex;
            align-items: center;
        }
    }
}

.holderSpacing {
    & > * {
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.skillsetHeadingNav {
    @apply --paragraphAntiSM;

    display: flex;
    justify-content: space-between;

    margin-top: 32px;
    padding-bottom: 8px;

    border-bottom: 1px solid #e6e6e6;

    & svg {
        fill: none;
        margin-left: 8px;
        cursor: pointer;
    }

    & > * {
        &:nth-of-type(1) {
            @apply --firstColumn;
        }

        &:nth-of-type(2) {
            @apply --secondColumn;
        }
    }

    @media (--viewportSmallMax) {
        margin-top: 24px;
    }
}
.editUserProfileItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;

    & > div {
        &:nth-of-type(1) {
            display: flex;

            @apply --firstColumn;
            flex-shrink: 0;

            & > span {
                max-width: 135px;
            }
        }

        &:nth-of-type(2) {
            @apply --paragraphAntiMedium;
            @apply --secondColumn;
            margin-left: auto;
        }
    }

    & .editUserProfileLabel,
    & .editUserSkillAction {
        & > span {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    & svg {
        fill: none;
        cursor: pointer;
    }

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}
.editUserDisciplinesSubsection {
    @apply --paragraphAntiSM;
    margin: 8px 0 0 0;
}

.levelInfoTooltip {
    bottom: initial;
    top: 100%;
    left: -111px;

    min-width: 208px;
    box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.1);

    &::after {
        bottom: initial;
        left: initial;
        right: 22px;
        top: -8px;

        transform: translateX(-50%);
        border-bottom: 8px solid white;
        border-top: none;
    }
}

.autocompleteDefaultItem {
    @apply --autocompleteDefaultItem;
}

.documentsSection {
    @apply --documentsSection;
    max-width: 432px;
}
.loadingOverlay {
    @apply --loadingOverlay;
}
.discardAction {
    color: #ff0000;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-weight: 600;
    letter-spacing: -0.25px;
    margin-top: 16px;

    &:hover {
        cursor: pointer;
    }

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
        font-weight: 600;
    }
}

.preOverlay {
    @media (--viewportSmallMax) {
        padding-bottom: 212px;
    }
}

.trainingSection {
    @media (--viewportSmallMax) {
        & label span {
            @apply --fontSizeSM;
        }
    }
}
.trainingYearsSection {
    display: flex;

    & > div {
        flex: 1;

        &:first-of-type {
            margin-right: 24px;
        }
    }
}

.editMobilityAddress {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 32px 0 24px 0;

    @media (--viewportSmallMax) {
        margin: 0 0 24px 0;
    }
}
.primaryLocationUpperElem {
    margin-right: 16px;
}
.secondaryLocationUpperElem {
    & svg {
        margin-right: 8px;
    }

    & path {
        fill: #8f2593;
    }

    & a {
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none;
        }
    }
}

.option {
    display: flex;
    justify-content: space-between;

    padding: 8px 16px;
    margin: 0;

    & > span:last-of-type {
        color: #b2b2b2;
    }

    &:hover {
        cursor: pointer;
        background: #f2f2f2;
    }
}

.helperText {
    @apply --paragraphAntiSM;
    margin-bottom: 24px;
    margin-top: -14px;
}

.sideNote {
    @apply --paragraphAntiSM;

    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    & > img {
        margin-right: 4px;
    }
}

.sideNoteTooltip {
    z-index: 1;
    width: 432px;

    @media (--viewportSmallMax) {
        width: auto;
    }

    & svg {
        fill: none;

        z-index: 1;
        pointer-events: auto;

        position: absolute;
        right: 8px;
        top: 8px;

        width: 18px;
        height: 18px;

        margin: 0;

        cursor: pointer;

        & path {
            stroke: #1a2b49;

            &:first-of-type {
                stroke: white;
            }
        }
    }
}
.sideNotTooltipTarget {
    justify-content: center;
}
.descriptionTextarea {
    & textarea {
        min-height: 184px !important;

        @media (--viewportSmallMax) {
            min-height: 116px !important;
        }
    }
}

.currentJobField {
    &,
    &:hover {
        border: none !important;
        background: none !important;
    }

    & label {
        padding: 0 !important;
    }
}

.levelsHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
}

.levelItem {
    color: #8f2593;
    margin: 0;
    cursor: pointer;

    @media (--viewportSmallMax) {
        @apply --fontSizeSM;
    }
}

.mb16 {
    margin-bottom: 16px;
}
.mb24 {
    margin-bottom: 24px;
}
.mt8 {
    margin-top: 8px;
}
.mt4 {
    margin-top: 4px;
}
.mt24 {
    margin-top: 24px;
}
.blck {
    display: block;
}
