@import '../../../marketplace.css';

.directionsWrapper {
  display: flex;
  justify-content: space-between;

  align-items: center;
  column-gap: 24px;

  @media (--viewportLarge) {
    width: 100%;
    max-width: 1150px;

    justify-content: flex-start;
  }
}

.directionText {
  margin-bottom: 0;
}

.actionWrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &:first-of-type {
    & svg {
      transform: rotate(180deg);
    }
  }

  & > svg > path {
    fill: #1a2b49;
    stroke: #1a2b49;
  }

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &:hover {
      cursor: pointer;
    }
  }
}

.actionDisabled {
  & > p {
    color: #b2b2b2;
  }

  & > svg > path {
    fill: #b2b2b2;
    stroke: #b2b2b2;
  }

  &:hover {
    cursor: not-allowed;
  }
}
