@import '../../../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --TabNav_linkBorderWidth: 4px;
    --iconHolder: {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;

        border-radius: 50%;
    }
}

.root {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow-x: auto;
}

.link {
    display: block;
    white-space: nowrap;

    width: 100%;
    margin: 0;

    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e6e6e6;
    border-bottom: 4px solid #e6e6e6;
    background-image: none;
    background-position: none;

    /* Font */
    @apply --marketplaceTabNavFontStyles;

    color: var(--matterColor);

    padding: 16px 24px 16px 16px;

    -webkit-transition: var(--transitionStyleButton);
    -o-transition: var(--transitionStyleButton);
    transition: var(--transitionStyleButton);
    /* SelectedLink's width (200px) + border thickness (4px) = 204px */

    @media (--viewportMediumMax) {
        padding: 16px;

        &:hover {
            text-decoration: none;
            color: var(--matterColorDark);
        }
    }

    @media (--viewportMedium) {
        width: 480px;
        height: 84px;

        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
}

.disabled {
    pointer-events: none;
    color: var(--matterColorAnti);
    text-decoration: none;
}

.tabWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 16px;
    -moz-column-gap: 16px;
    column-gap: 16px;
}

.textContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.textTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.25px;
    margin: 0 0 4px 0;
}

.subLabelWrapper {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
}

.tabWithBorder {
    border-bottom: 0px !important;
    background-repeat: no-repeat !important;
    background-size: 100% 4px !important;
}

.completedTab {
    border-radius: 4px !important;
    border-bottom: 1px solid #e6e6e6 !important;

    &:hover {
        border-bottom: 1px solid #e6e6e6 !important;
    }
}

.tabArrowContainer {
    display: flex;

    margin-left: auto;

    /* @media (--viewportMedium) {
        margin-right: 8px;
    } */
}

.arrowIcon {
    width: 22px;
    height: 22px;

    & path {
        stroke: #1a2b49;
        fill: #1a2b49;

        stroke-width: 0.5px;
    }
}

.arrowIconDisabled {
    & path {
        stroke: #b2b2b2;
        fill: #b2b2b2;
    }
}

.tabIndex {
    @apply --iconHolder;
    @apply --fontSizeMM;

    font-weight: 600;

    flex-shrink: 0;
    background-color: #f9f4fa;
    color: #8f2593;
}
.tabIndexDisabled {
    color: #b2b2b2;
    background-color: #e6e6e6;
}

.tabCompletedIconHolder {
    @apply --iconHolder;
    background-color: #1e9f55;

    & path {
        stroke: white;
    }
}

.enabled {
    &:hover {
        cursor: pointer;
    }
}
