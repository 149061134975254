@import './MissingPhoneNumberModal.css';

.completeProfileHeader {
    margin-top: 16px;
    margin-bottom: 16px;

    letter-spacing: -0.5px;
    font-weight: 700;

    font-size: 32px;
    line-height: 40px;
}

.completeProfileDescription {
    & br {
        display: none;
    }

    @media (--viewportSmallMax) {
        margin-bottom: 40px;

        & br {
            display: initial;
        }
    }
}

.otpField {
    margin-bottom: 24px;
}

.modalNotification {
    @apply --paragraphAntiSM;
    color: #8f2593;
}

.link {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.error {
    position: static;
}
