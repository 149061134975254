@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
    --SearchPage_search_filters_panel_large: 72px;
    --SearchPage_search_filters_panel_medium: 60px;

    --SearchPage_map_open {
        position: fixed;
        top: 0;
        right: 0;
    }

    --SearchPage_map_panel_viewport {
        @media (--viewportLarge) {
            width: 37.5vw;
        }
    }
}

.placeholder {
    @apply --SearchPage_map_panel_viewport;
}

.page {
    /* for filters popups overlay map being open as a modal */
    position: unset !important;
}
/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
    z-index: initial;
}

.topbar {
    @media (--viewportMedium) {
        position: sticky;
        top: 0;
        left: 0;
        /* We need to raise Topbar above .container */
        z-index: 100;
    }

    &.topbarHidden {
        display: none;
    }
}

.topbarDesktop {
    box-shadow: unset;
    border-bottom: 1px solid #e6e6e6;
}

.container {
    /* Layout */
    width: 100%;
    flex-grow: 1;
    display: flex;

    @media (--viewportMedium) {
        position: relative;
        padding-top: 0px;
        min-height: var(--SearchPage_containerHeight);
    }
}

.searchResultContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0 24px 0;

    @media (--viewportMedium) {
        flex-basis: 50%;
    }

    @media (--viewportLarge) {
        overflow: hidden;

        flex-basis: 62.5%;
        padding: 24px 0 32px 0;
    }
}

.dynamicDisplaycontainer {
    /* Layout */
    padding: 0.7rem;
    width: 100%;
    flex-grow: 1;
    display: flex;

    @media (--viewportMedium) {
        position: relative;
        /* min-height: var(--SearchPage_containerHeight); */
        min-height: auto;
    }
}

.dynamicDisplaySearchResultContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 0;

    @media (--viewportMedium) {
        /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    }

    @media (--viewportLarge) {
        flex-basis: 62.5%;
    }
}

.error {
    color: var(--failColor);
}

.searchString {
    /* Search string should not break on white spaces - i.e. line-break should happen before. */
    white-space: nowrap;
}

.searchFilters {
    display: flex;
    flex-wrap: nowrap;
}

.searchFiltersPanel {
    flex-grow: 1;
    display: flex;
    padding: 0 24px 24px 24px;

    @media (--viewportLarge) {
        padding: 0 36px 36px 36px;
    }
}
.searchFiltersWithMapOpen {
    /* map modal scroll layer z-index + 1 */
    z-index: 101;
}

.mapWrapper {
    width: 100vw;
    height: calc(100vh - var(--SearchPage_search_filters_panel_large));

    /* --viewportLarge */
    @apply --SearchPage_map_panel_viewport;

    &.mapPanelOpen .map {
        /* search panel map is open */
        width: 100vw;
        height: 100%;
        z-index: 5;
    }

    @media (--viewportMediumMax) {
        /* margin-top: var(--SearchPage_search_filters_panel_large); */
        position: fixed;
        top: 0;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        height: 100vh !important;
    }

    &.mapPanelOpen {
        @media (--viewportMediumMax) {
            z-index: 5;
            opacity: 1;
            pointer-events: auto;
        }
    }
}
.map {
    width: 100vw;
    height: 100vh;

    @media (--viewportLarge) {
        @apply --SearchPage_map_open;
        /* width: 50vw; */
    }
    /* --viewportLarge */
    @apply --SearchPage_map_panel_viewport;
}

.modalContainer {
    flex-basis: 100%;
    margin: 0;
    height: 100%;
}

.modalContent {
    height: 100%;
}

.mapModalRoot {
    top: calc(var(--SearchPage_search_filters_panel_large) + var(--topbarHeightDesktop));

    & .mapModalScrollLayer {
        min-height: calc(
            100vh - var(--SearchPage_search_filters_panel_large) - var(--topbarHeightDesktop)
        );
    }

    @media (--viewportSmallMax) {
        top: var(--SearchPage_search_filters_panel_large);

        & .mapModalScrollLayer {
            min-height: calc(100vh - var(--SearchPage_search_filters_panel_large));
        }
    }
}
