@import '../../marketplace.css';

.root {
    position: relative;
    width: 100%;
    height: 100%;
}

.track {
    /* Position */
    position: absolute;
    top: 50%;
    margin-top: -2px;
    margin-left: -10px;

    /* Layout */
    width: 100%;
    height: 8px;
    padding: 0 10px;

    box-sizing: content-box;
    background-color: var(--matterColorNegative);
    border-radius: 4px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.range {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    height: 8px;
    background-color: var(--marketplaceColor);
}
