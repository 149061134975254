@import '../../../../marketplace.css';

.error {
    color: var(--failColor);
}

.listingSectionTitle {
    @apply --fontSizeXL;

    font-weight: 700;
    margin: 0 0 24px 0;

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }
}
