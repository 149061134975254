@import '../../marketplace.css';

.root {
    /* Size from content */
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    border: 0;

    &:hover {
        & .details,
        & .caret {
            cursor: pointer;

            /* Same as active */
            box-shadow: var(--boxShadowPopup);
        }
    }
}

.total,
.sufficient,
.insufficient {
    & .details {
        border: 1px solid white;
    }
}

.total {
    & .details {
        color: #1e9f55;
        background: #f0fff6;
    }
    & .caret {
        background: #f0fff6;
    }
}

.sufficient {
    & .details {
        background-color: #fff7f0;
        color: #ffaa00;
    }
    & .caret {
        background: #fff7f0;
    }
}

.insufficient {
    & .details {
        background-color: #fff0f0;
        color: #ff0000;
    }
    & .caret {
        background: #fff0f0;
    }
}

.detailsVisited {
    &.details {
        border: 2px solid #b2b2b2;
        background: #e6e6e6;
    }
}

.caretVisited {
    &.caret {
        border-right: 2px solid #b2b2b2;
        border-bottom: 2px solid #b2b2b2;
        box-sizing: initial;
        background: #e6e6e6;
    }
}

.detailsActive {
    &.details {
        background-color: var(--marketplaceColor);
        border-color: var(--marketplaceColor);
        color: var(--matterColorLight);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    }
}

.caretActive {
    &.caret {
        background-color: var(--marketplaceColor);
        border-color: var(--marketplaceColor);
    }
}

.details {
    /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
    position: relative;

    width: 36px;
    height: 36px;
    /* TODO: use flexCentered */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Font */
    @apply --marketplaceH4FontStyles;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;

    padding: 6px 14px;

    /* Coloring */
    background-color: var(--matterColorLight);
    color: var(--matterColor);

    /* Borders */
    border-radius: 50%;
    border-style: solid;
    border-color: var(--matterColorNegative);
    border-width: 1px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    transition: var(--transitionStyleButton);

    /* Overwrite dimensions from font styles */
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }

    &.doubleDigit {
        font-size: 15px;
    }
    &.tripleDigit {
        font-size: 14px;
    }
}

.caretShadow {
    /* Caret / arrow dimensions and position */
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -3px;
    transform: rotate(45deg);

    /* Caret should have same box-shadow as label */
    box-shadow: var(--boxShadowPopupLight);
}

.caret {
    /* Caret / arrow dimensions and position */
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: -3px;
    left: calc(50% - 0.5px);
    margin-left: -3px;
    transform: rotate(45deg);

    /* Caret should have same bg-color as label */
    background-color: var(--matterColorLight);

    transition: var(--transitionStyleButton);
}
