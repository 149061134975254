@import '../../marketplace.css';

:root {
}

.root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 70px;

    position: sticky;
    top: 0;
    left: 0;

    margin: 0;

    column-gap: 8px;
    z-index: 6;
    background-color: var(--matterColorBright);

    /* @media (--viewportLarge) {
        position: fixed;
        top: 60px;

        padding: 16px 24px;

        justify-content: flex-start;
    } */

    @media (--viewportLarge) {
        position: fixed;
        /* top: 60px; */

        padding: 16px 24px;

        justify-content: flex-start;
        height: 72px;
        /* top: 72px; */
        top: 60px;
        column-gap: 16px;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
    }
    /* for mob view */
    &.scrolled {
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
    }
}

.longInfo {
    flex-wrap: wrap;
}

.filters {
    display: flex;
    align-items: center;
    gap: 16px;
}

.sortingPanel {
    width: 100%;
    margin-bottom: 24px;
}

.searchResultsUpperSection {
    padding: 16px 24px 32px 24px;

    /* @media (--viewportMedium) {
        padding: 70px 24px 32px 24px;
    } */

    @media (--viewportLarge) {
        padding: 62px 0 32px 24px;
    }
}

.searchResultsUpperSectionEmailBar {
    /* @media (--viewportMedium) {
        padding-top: 76px;
    } */

    @media (--viewportLarge) {
        padding-top: 62px;
    }
}

.searchResultSummary {
    @apply --fontSizeMM;

    /* parent uses flexbox: this defines minimum width for text "6 results" */
    flex-basis: 55px;
    flex-shrink: 0;

    margin: 0;

    @media (--viewportLarge) {
        padding-top: 32px;
    }
}

.searchResultSummaryMobPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        column-gap: 8px;
    }
}

.infoText {
    @apply --paragraphAntiSM;

    display: flex;
    align-items: center;
    gap: 8px;

    margin: 0;

    @media (--viewportMediumMax) {
        margin-top: 16px;
        justify-content: center;
    }
}

.tooltipClass {
    /* to prevent favorite card heart icon to overflow tooltip content, */
    /* set z-index to favoriteWrapper + 1 value */
    z-index: 6;

    & + div {
        display: flex;
    }
}

.tooltipWrapper {
    &:after {
        z-index: 5;
    }
}

.noSearchResults {
    @apply --marketplaceH4FontStyles;

    /* Reserves 48px of vertical space */
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 12px;

    /* parent uses flexbox: this defines minimum width for translated text "noResults" */
    flex-basis: 100%;
    flex-shrink: 0;
}

.desiredDisciplineFilter {
    @media (--viewportLarge) {
        & > div {
            padding: 6px 0 0 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;

            max-height: calc(100vh - 206px);
            overflow-y: auto;
            overflow-x: hidden;

            @apply --scrollBarStyled;
        }
    }
}
.mainDisciplinePopup {
    margin-bottom: 17px;

    & > [data-sl] {
        display: none;
    }
}

.loadingResults {
    @apply --marketplaceH4FontStyles;
    line-height: 20px;

    /* Cover parent element's space*/
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* Layout */
    padding: 9px 24px 0 24px;
    margin: 0;
    background-color: var(--matterColorBright);
    z-index: 2;
}

.resultsFound {
    margin: 0;

    max-width: calc(100vw - 158px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (--viewportLarge) {
        max-width: unset;
        margin-bottom: 4px;
    }
}

.searchFiltersPanelClosed {
    @apply --marketplaceButtonStylesSecondary;
    @apply --marketplaceSearchFilterLabelFontStyles;
    @apply --marketplaceFilterLabel;

    display: flex;
    column-gap: 8px;
    white-space: nowrap;

    &:focus {
        @apply --marketplaceFilterLabelFocused;
    }
}

.searchFilterWithIcon {
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.searchFiltersPanelOpen {
    @apply --marketplaceButtonStyles;
    @apply --marketplaceSearchFilterLabelFontStyles;
    @apply --marketplaceFilterLabel;

    font-weight: var(--fontWeightSemiBold);

    display: inline-block;

    border: 1px solid var(--marketplaceColor);

    &:hover,
    &:focus {
        border: 1px solid var(--marketplaceColorDark);
    }
}

.searchFiltersPanelSelected {
    &,
    &:hover,
    &:focus {
        background-color: #f9f4fa;
        border: 2px solid #8f2593;
        color: #8f2593;

        & path {
            stroke: #8f2593;
        }
    }
}

.sortSearchAgentWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (--viewportLarge) {
        gap: 16px;
        margin-left: auto;
    }
}

.infoIcon {
    width: 16px;
    height: 16px;

    & path {
        fill: #b2b2b2;
    }

    &:hover {
        cursor: pointer;
    }
}

.mob {
    @media (--viewportLarge) {
        display: none;
    }
}

.desktop {
    @media (--viewportMediumMax) {
        display: none;
    }
}

.filterPanelMobIcon {
    height: 34px;
    width: 34px;

    position: absolute;
    padding: 0;
    right: 6px;
    top: calc(50% - 17px);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    border: 1px solid #e6e6e6;
    background: #fff;

    z-index: 2;

    transition: 0.35s;

    &:hover {
        border: 1px solid #1a2b49;
        cursor: pointer;
    }

    &.filterPanelMobIconSelected {
        background: #f9f4fa;
        border: 2px solid #8f2593;

        & path {
            fill: #8f2593;
        }
    }
}

.filterBadgeMob {
    width: 100%;
    margin: 0 24px;

    & > section {
        display: flex;
        align-items: center;

        height: 48px;

        & > div {
            /* dumbFieldContainer */
            width: 100%;
        }

        & input {
            height: unset;

            & + div:not(.filterPanelMobIcon) {
                right: 60px;
            }
        }
    }
}

.mapMobBadge {
    display: flex;
    align-items: center;

    position: fixed;
    bottom: 32px;
    z-index: 4;

    left: calc(50% - 51px);

    border: 1px solid transparent;
    background-color: white;
    padding: 10px 16px;
    border-radius: 37px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    opacity: 1;
    transition: 0.35s;

    @apply --fontSizeSM;

    & svg {
        margin-right: 8px;
    }

    &:hover {
        cursor: pointer;
        border: 1px solid #1a2b49;
    }
    &.hidden,
    &.faded {
        opacity: 0;
        pointer-events: none;
    }

    @media (--viewportLarge) {
        display: none;
    }
}
