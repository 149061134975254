@import '../../marketplace.css';

@keyframes overlayShadowing {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes mmShadowing {
    0% {
        background: linear-gradient(90.09deg, #f2f2f2 3.64%, #e6e6e6 98.2%);
    }

    50% {
        background: linear-gradient(90.09deg, #f2f2f2 3.64%, #f2f2f2 98.2%);
    }

    100% {
        background: linear-gradient(-90.09deg, #f2f2f2 3.64%, #e6e6e6 98.2%);
    }
}

:root {
    --ListingCard__AvatarOuterSpace {
        margin-left: auto;
        margin-right: 20px;
    }

    --ListingCard__AvatarStyles {
        margin-top: -48px;
        position: relative;
        z-index: 1;
    }

    --horizontalPadding {
        padding: 0 18px;
    }
}

.root {
    /* Layout */
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 8px;
}

.cardWrapper {
    @apply --listing_card_wrapper;
}

.listingRef {
    text-decoration: none;
    color: #1a2b49;

    &:hover {
        text-decoration: none;
    }
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
    background: var(--matterColorNegative); /* Loading BG color */
}

.aspectWrapperNoBackground {
    background: unset;
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
}

.infoLine {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.infoItem {
    @apply --fontSizeXS;

    padding: 3px 8px;

    border: 1px solid #e6e6e6;
    border-radius: 24px;

    margin: 0 8px 8px 0;
}

.additionalInfoItem {
    @apply --paragraphAntiSM;
    white-space: nowrap;

    padding: 4px 8px;
}

.capitalized {
    text-transform: capitalize;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: var(--matterColorNegative);

    margin: 16px 0;
}

.horseListingSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoItemMatches {
    display: flex;
    align-items: center;

    border: 1px solid #1e9f55;

    & > svg {
        margin-right: 3px;
        width: 14px;
        height: 14px;
    }
}

.price {
    /* Layout */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
}

.createdDate {
    display: flex;
    align-items: center;
    gap: 5px;

    & svg {
        fill: none;
    }

    & span {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 400;
    }
}

.priceContainer {
    display: flex;
    gap: 6px;
    align-items: flex-end;
}

.priceValue {
    @apply --fontSizeXL;
    color: var(--marketplaceColor);
    font-weight: 700;

    margin-right: 6px;
}

.perUnit {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);
    font-size: 14px;
    line-height: 18px;
    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.listingCreationInfo {
    display: flex;
    align-items: center;

    @apply --paragraphAntiSM;

    & svg {
        flex-shrink: 0;
        margin-right: 6px;

        width: 16px;
        height: 16px;
    }
}

.createdAtIcon {
    & path {
        fill: #b2b2b2;
    }
}

.title {
    @apply --fontSizeXL;
    color: white;
    font-weight: 700;

    margin: 0 0 4px 0;
}

.authorInfo {
    /* Font */
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);
    /* Remove default margins from font */
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.avatarAuthor {
    @apply --ListingCard__AvatarStyles;

    & > div {
        @apply (--ListingCard__AvatarOuterSpace);
    }
}
.listingInfoSection {
    padding: 16px;
}
.locationHolder {
    display: flex;
    align-items: center;

    color: white;
    white-space: nowrap;
    height: 22px;
    margin-bottom: 5px;

    & svg {
        fill: none;
        margin-right: 4px;

        & path {
            stroke: white;
        }
    }

    & span {
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;

        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.locationDisctance {
    position: relative;
    margin-left: 12px;

    & + svg {
        fill: #9c9c9c;
        width: 4px;
        height: 4px;
    }
}

.locationInfo {
    display: flex;
    align-items: center;

    & svg {
        fill: white;
        width: 4px;
        height: 4px;
        position: relative;
        right: -10px;
        top: 1px;
    }
}

.newListingNotification {
    @apply --fontSizeXS;
    color: white;
    font-weight: 900;

    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;

    padding: 6px 8px;

    background-color: #8f2593;
    border-radius: 8px 0 8px 0;
}

.availabilityBadge {
    position: absolute;
    top: 16px;
    left: 16px;
    /** to handle tooltip being visible */
    z-index: 3;
}

.overlayBottom {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);

    pointer-events: none;
}

.galleryCarouselWrapper {
    z-index: 1;

    & > div:last-of-type {
        z-index: 2;
    }

    & video {
        pointer-events: none;
    }
}

.progressBar {
    display: block;
    height: 4px;
    width: 100%;
    background: linear-gradient(359.79deg, #f2f2f2 0.19%, #e6e6e6 99.81%);

    & > mark {
        display: block;
        height: 100%;
        width: 0;
        border-radius: 0 2px 2px 0;
    }
}

.insufficient {
    background: #fff0f0;

    & > mark {
        background: #ff0000;
    }
}

.sufficient {
    background: #fff7f0;

    & > mark {
        background: #ffaa00;
    }
}

.total {
    background: #f0fff6;

    & > mark {
        background: #1e9f55;
    }
}

.mmLoadingIndicator {
    display: inline-block;
    width: 160px;
    height: 18px;
    border-radius: 16px;

    margin-bottom: 8px;

    position: relative;

    &:after,
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        border-radius: inherit;

        animation-name: overlayShadowing;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    &:after {
        background: linear-gradient(90.09deg, #f2f2f2 3.64%, #e6e6e6 98.2%);
    }

    &:before {
        background: linear-gradient(-90.09deg, #f2f2f2 3.64%, #e6e6e6 98.2%);
        animation-delay: 0.75s;
    }
}

.tooltipContent {
    z-index: 2;

    @media (--viewportSmallMax) {
        min-width: 322px;
    }

    & p {
        font-size: 14px !important;
    }
}

.tooltip {
    & svg {
        stroke: #b2b2b2;
        fill: none;

        &:hover {
            cursor: not-allowed;
        }
    }
}

.loadingOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        animation-name: overlayShadowing;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    &:after {
        background: linear-gradient(45deg, #e6e6e6, #333333d9);
    }

    &:before {
        background: linear-gradient(45deg, #333333d9, #e6e6e6);
        animation-delay: 1s;
    }

    & svg {
        z-index: 1;

        fill: transparent;

        position: absolute;
        left: calc(50% - 33.5px);
        top: calc(50% - 36px);

        & path {
            stroke: white;
        }

        @media (--viewportSmallMax) {
            display: none;
        }
    }
}

.identitySection {
    position: absolute;
    left: 16px;
    bottom: 40px;

    z-index: 3;

    pointer-events: none;
}

.actionItem {
    padding-bottom: 50%;
    position: absolute;
    height: 100%;

    left: 0;
    right: 0;

    & > button {
        width: 176px;

        position: absolute;
        top: calc(50% - 32px);
        left: calc(50% - 88px);

        @media (--viewportMedium) {
            width: 226px;
            left: calc(50% - 113px);
        }
    }
}

.recommendationsNumSection {
    margin-left: 16px;
    margin-bottom: 0;

    display: flex;
    align-items: center;

    @apply --fontSizeXS;
    white-space: break-spaces;

    & svg {
        width: 16px;
        height: 16px;
    }
}

.tempListing {
    display: flex;
    align-items: center;

    margin-left: 16px;
}

.matchingScore {
    font-weight: 600;
}
