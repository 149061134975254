@import '../../marketplace.css';

.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.newSearchInProgress {
    opacity: 0.1;
}

.listings {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.listingCards {
    padding: 0 24px 40px 24px;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media (--viewportLarge) {
        padding: 0 24px 80px 24px;
    }
}

.listingCardsInLanding {
    justify-content: center;
}

.listingCard {
    margin-bottom: 30px;
    flex-basis: 100%;
    /** @media screen and (min-width: 900px) and (max-width: 1920px) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */

    /**@media screen and (min-width: 720px) and (max-width: 900px) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */

    @media (--viewportLarge) {
        /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
        flex-basis: calc(49% - 12px);
        margin-right: 24px;
    }

    @media (--viewportXLarge) {
        /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

        flex-basis: calc(33.33% - 16px);
        margin-right: 24px;
    }

    & [data-tooltip='item'] * {
        pointer-events: none;
    }
}

.activeListing {
    box-shadow: 0px 0px 0px 4px #8f2593 !important;
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
    @media screen and (min-width: 1024px) and (max-width: 1920px) {
        margin-right: 0;
    }
}

.listingCard:nth-of-type(3n) {
    @media (--viewportXLarge) {
        margin-right: 0;
    }
}

.dynamicListingCard:nth-of-type(2n) {
    @media screen and (min-width: 720px) and (max-width: 900px) {
        margin-right: 0;
    }
}

.dynamicListingCard:nth-of-type(3n) {
    @media screen and (min-width: 900px) {
        margin-right: 0;
    }
}

.pagination {
    /* margin-top: auto; pushes pagination to the end of the page. */
    margin-top: auto;
    background-color: var(--matterColorLight);
    padding: 8px 24px;
}
.preflightSection,
.noResultspreflightSection {
    padding: 0 24px 0 24px;

    @media (--viewportLarge) {
        & h4 {
            @apply --fontSizeXXL;
        }
    }

    & h4 {
        @apply --fontSizeXL;
        font-weight: 700;
        margin: 0 0 8px 0;
    }

    p {
        margin: 0;
    }
}

.preflightSection {
    border-top: 1px solid #e6e6e6;

    padding-top: 32px;
    margin: 32px 0;
}
.subSection {
    padding-bottom: 0;
}
