@import '../../marketplace.css';

.backdrop {
    position: absolute;
    z-index: 1;
    overflow: auto;
    pointer-events: none;
    transition: transform 1s;
}

.highlights {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    color: transparent;
    /* see --focusedPadding:from FieldTextInput */
    padding: 22px 60px 4px 17px;
}

.mark {
    border-radius: 3px;
    color: transparent;
    background-color: unset;
}

.markhighlighted {
    color: #ff0000;
    opacity: 0.9;
}

textarea {
    z-index: 2;
}

.markOverlayed {
    color: white;
}

.markOverlayed,
.markhighlighted {
    ::selection {
        color: white;
    }
}
