@import '../../marketplace.css';

:root {
    --hidden-divider: {
        & ~ .divider {
            display: none;
        }
    }
}

.root {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;

    background: white;
    padding: 12px;
    padding-left: calc(4vw + 205px);
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
}

.searchInputIcon {
    display: none;
}

.form {
    display: flex;
    align-items: center;

    max-width: 700px;
    width: 100%;

    border: 2px solid #e6e6e6;
    position: relative;
    height: 80px;

    border-radius: 48px;
}

.searchActionButton {
    /* submit */
    @apply --fontSizeMM;
    font-weight: 600;

    display: flex;
    column-gap: 8px;

    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;
    z-index: 4;

    border-radius: 4px;
    background: #8f2593;
    color: white;

    padding: 16px;
    transition: 0.35s;

    &.disabled {
        &,
        &:hover {
            background: #e6e6e6;
            color: #b2b2b2;
            cursor: default;
        }

        & path {
            stroke: #b2b2b2;
        }
    }

    &:hover {
        background: var(--marketplaceColorDark);
        text-decoration: none;
    }

    & svg {
        width: 24px;
        height: 24px;
        stroke-width: 1.75px;
        margin-right: 8px;
    }

    @media (--viewportMediumMax) {
        &:hover {
            text-decoration: none;
        }

        position: fixed;
        bottom: 24px;

        left: calc((100vw - var(--mobile-search-panel-max-width) + 24px) / 2);
        right: calc((100vw - var(--mobile-search-panel-max-width) + 24px) / 2);

        @media (max-width: 480px) {
            left: 24px;
            right: 24px;
        }
    }

    @media (--viewportLarge) {
        position: absolute;
        right: 10px;
        top: calc(50% - 28px);

        height: 56px;
        min-height: 56px;
        border-radius: 56px;

        width: 56px;
        padding: 0;

        & > span {
            display: none;
        }

        & svg {
            width: 32px;
            height: 32px;
            stroke-width: 1px;
            margin-right: unset;
        }

        &.focus {
            width: auto;
            padding: 16px;

            & > span {
                display: inline-block;
            }
        }
    }
}

.locationFieldContainer,
.distancePanelContainer {
    border-radius: 16px;
    padding: 14px;

    & input {
        padding: 0;
        height: auto;
        border: none;
    }

    & > label {
        @apply --fontSizeXS;
        font-weight: 600;
        padding: 0 0 4px 0;
    }

    &:hover {
        border: 2px solid #b2b2b2;
    }
    &.focused {
        border: 2px solid #8f2593;
    }
    &.notAllowedForMapSearch {
        &,
        & input {
            pointer-events: none;
        }

        & label {
            color: #b2b2b2;
        }
    }
    /* MOB */
    @media (--viewportMediumMax) {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        border: 2px solid transparent;
    }
    /* DESKTOP */
    @media (--viewportLarge) {
        position: absolute;
        padding: 12px 24px;

        &:hover,
        &.focused {
            border-radius: 48px;
        }
    }
}

.locationFieldContainer {
    width: 100%;
    background: #fff;
    /* MOB */
    @media (--viewportMediumMax) {
        margin-bottom: 24px;

        & input ~ svg {
            right: 0;
        }
    }
    /* DESKTOP */
    @media (--viewportLarge) {
        max-width: 448px;
        left: -2px;

        border-radius: 48px 0 0 48px;
        border: 2px solid #e6e6e6;
        border-right: none;

        &.focused {
            @apply --hidden-divider;

            /** IconCloseCircleL */
            & svg {
                right: 0;
            }
        }
    }

    & input + svg {
        top: calc(50% - 24px);
    }
}

.distancePanelContainer {
    position: relative;
    background: #fff;

    @media (--viewportLarge) {
        padding-right: 104px;

        border-radius: 0 48px 48px 0;
        border: 2px solid #e6e6e6;
        border-left: none;

        &:hover,
        &.focused {
            padding: 12px 24px 12px 22px;
        }
    }
}

.distancePanel {
    position: absolute;
    right: 0;
    left: calc(50% - 126px);
    top: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 252px;
    padding: 16px 0px;
    margin-top: 4px;

    border-radius: 32px;
    background: #fff;
    /* Shadow - surround */
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    overflow: hidden;

    @media (--viewportMediumMax) {
        border-radius: 16px;
        width: 100%;
        left: 0;
        z-index: 5;
    }

    /* radio button list */
    & > div {
        &,
        &:last-of-type {
            @apply --radionDropDownListItem;
        }
    }
}

.locationFieldContainer,
.distanceWrapper {
    &:not(.notAllowedForMapSearch) {
        &:hover {
            cursor: pointer;
            @apply --hidden-divider;
        }
    }

    &.notAllowedForMapSearch {
        &:hover {
            cursor: not-allowed;
        }
    }
}

.distanceWrapperFocused {
    @apply --hidden-divider;
}

.distanceWrapper {
    width: 100%;

    @media (--viewportLarge) {
        right: -2px;
        position: absolute;

        max-width: 252px;
    }
}

.divider {
    width: 1px;

    position: absolute;
    left: 445px;
    top: 8px;
    bottom: 8px;

    z-index: 1;

    background-color: #e6e6e6;

    @media (--viewportMediumMax) {
        display: none;
    }
}

.topbarDesktopSearchPredictions {
    position: absolute;
    left: 0;
    right: 0;
    top: 44px;

    max-width: 398px;
    padding: 16px 0;

    background: white;
    border-radius: 32px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    @media (--viewportMediumMax) {
        border-radius: 16px;
        max-width: unset;
        top: 48px;
        left: -16px;
        right: -16px;

        z-index: 5;
    }
}

.distanceArrowPointer {
    position: absolute;
    right: 24px;
    top: calc(50% - 12px);

    transition: 0.35s;
    transform: rotate(-90deg);

    pointer-events: none;

    @media (--viewportLarge) {
        display: none;
    }

    &.distanceArrowPointerFocused {
        transform: rotate(90deg);
    }
}

.overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;
}
