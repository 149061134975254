@import './MissingPhoneNumberModal.css';

.completeProfileWrapper {
    & > svg {
        margin: 16px 0;
    }
}

.completeProfileIcon {
    margin: 16px 0;
}

button.closeButton {
    margin-top: auto;
    @media (--viewportMedium) {
        margin-top: 24px;
    }
}
