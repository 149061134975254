@import '../../marketplace.css';

.layoutWrapper {
    width: 100%;
    max-width: 1150px;

    padding: 24px 24px 40px 24px;

    margin: 0 auto;

    @media (--viewportMedium) {
        padding: 64px 24px 80px 24px;
    }

    & > svg:first-of-type {
        display: none;
    }
}

.manageListingsHeading {
    & h3 {
        margin-top: 0;
    }

    & p {
        margin-bottom: 24px;
    }
}

.noResults {
    @media (--viewportMedium) {
        margin: 31px 0 0 86px;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    row-gap: 32px;

    @media (--viewportMedium) {
        & > * {
            margin-right: 32px;

            &:nth-of-type(2n + 2) {
                margin-right: 0;
            }
        }
    }

    @media (--viewportLarge) {
        & > * {
            &,
            &:nth-of-type(2n + 2) {
                margin-right: 32px;
            }

            &:nth-of-type(3n + 3) {
                margin-right: 0;
            }
        }
    }
}

.alert {
    margin-bottom: 32px;
}

.statesNavBar {
    display: flex;
    margin-bottom: 32px;
    column-gap: 8px;

    max-width: 100%;
    overflow: auto;

    @apply --scrollBarStyled;
}

.filterBadge {
    margin-bottom: 0;
    padding: 8px 16px;
    border-radius: 48px;
    border: 1px solid var(--matterColorNegative);

    white-space: nowrap;

    @apply --fontSizeSM;

    transition: 0.35s;

    &:hover {
        cursor: pointer;
        border: 1px solid var(--marketplaceColorDarkMedium, #1a2b49);
    }
}

.filterBadgeSelected {
    background-color: #f9f4fa;
    color: var(--marketplaceColor, #8f2593);

    border: 2px solid var(--marketplaceColor, #8f2593);

    &:hover {
        border: 2px solid var(--marketplaceColor, #8f2593);
    }
}
