@import '../../marketplace.css';

.nav {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;

    background: white;

    padding: 22px 0 0 26px;

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

    overflow-x: scroll;
    overflow-y: hidden;
    /** -1 from topbar z-index  */
    z-index: 54;

    @media (min-width: 920px) {
        overflow-x: hidden;
    }

    @media (--viewportMediumMax) {
        top: 60px;
        /** same as topbar z-index  */
        z-index: 55;
    }

    & a {
        flex-shrink: 0;

        @apply --fontSizeSM;
        text-decoration: none;
        color: var(--matterColorAnti);

        margin-right: 20px;
        margin-bottom: 0;
        padding-bottom: 8px;
        border-bottom: 3px solid transparent;

        transition: 0.35s;

        &:hover {
            cursor: pointer;
            color: #8f2593;
            border-bottom: 3px solid #8f2593;
            text-decoration: none;
        }

        &.selected {
            color: #8f2593;
            border-bottom: 3px solid #8f2593;
        }
    }

    @apply --scrollBarStyled;
}

.navBarEmailUnverified {
    top: 126px;

    @media (--viewportMediumMax) {
        top: 116px;
    }
}
