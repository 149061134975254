@import '../../marketplace.css';

.modalContainer {
    @apply --marketplaceModalBaseStylesCore;

    height: 100%;

    &,
    & > button {
        background-color: #f9f9f9;
    }
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.searchPanelRoot {
    width: 100%;
    max-width: var(--mobile-search-panel-max-width);

    position: static;

    position: static;
    background: none;
    box-shadow: unset;

    padding: 32px 0 0 0;

    & form {
        border: none;
        display: block;
    }
}

.scrollLayer {
    @apply --marketplaceModalRootStyles;
    @apply --scrollBarStyled;

    background-color: var(--matterColorLight);

    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
