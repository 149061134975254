@import '../../../../marketplace.css';

.block {
    display: flex;
    flex-direction: column;

    flex-basis: calc(50% - 12px);
    border: 1px solid #e6e6e6;
    border-radius: 24px;
    background: #fff;

    & svg {
        fill: #8f2593;
    }

    & section,
    & footer {
        padding: 32px;

        @media (--viewportMediumMax) {
            padding: 24px;
        }
    }

    & section {
        & > h4 {
            color: #8f2593;
            margin: 16px 0;
            @apply --fontSizeXXXL;

            @media (--viewportMediumMax) {
                @apply --fontSizeXL;
                font-weight: 700;
            }
        }
    }

    & footer {
        border-top: 1px solid #e6e6e6;
        margin-top: auto;

        & p {
            @apply --fontSizeXXXL;
            text-align: center;
            color: #8f2593;

            @media (--viewportMediumMax) {
                @apply --fontSizeXXL;
            }
        }

        & button {
            width: 100%;
        }
    }

    & .list {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        & h3 {
            @apply --fontSizeMM;
            font-weight: 700;
            margin: 0;
        }

        & p {
            margin: 0;
            display: flex;
            column-gap: 8px;
        }
    }

    & .additionalListItem {
        list-style: disc;
        &::marker {
            font-size: 16px;
        }
    }
}
