@import '../../../marketplace.css';

.root {
    width: 100%;

    margin-bottom: 16px;
    @apply --paragraphAntiSM;

    & path {
        fill: var(--matterColorAnti);
    }

    &,
    & > a {
        @apply --flexCentered;
        column-gap: 4px;
        text-align: center;
    }

    & .justNow {
        color: var(--successColor);
    }
    & .recently {
        color: #fa0;
    }
    & .longTimeAgo {
        color: #f00;
    }
}

.loggedOutLink {
    color: var(--marketplaceColor);

    & path {
        fill: var(--marketplaceColor);
    }
}
