@import '../../../marketplace.css';

.link {
    @apply --fontSizeMM;
}

.linkDisabled {
    color: #b2b2b2;
    cursor: not-allowed;
    @media (--viewportSmallMax) {
        display: none;
    }
}

.redirectDisabledTooltip {
    min-width: 342px;
}

.placeholderWrapper {
    & footer {
        margin-top: 32px;

        & > div {
            max-width: 637px;

            &:nth-of-type(1),
            &:nth-of-type(2) {
                margin-bottom: 16px;
                max-width: 100%;
            }
        }
    }
}

.viewProfileRequestWrapper {
    max-height: 276px;
}

aside.viewProfileRequestAction {
    height: 65%;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 0.5) 55%,
        rgba(255, 255, 255, 0.1) 100%
    );
}

.noBackBlock {
    background-color: white;
}

.mobLink {
    margin-top: 24px;
    display: block;

    @media (--viewportMedium) {
        display: none;
    }
}
