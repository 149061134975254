@import '../../marketplace.css';

.tooltipRoot {
    position: relative;
    z-index: 1;
}

.sectionItem {
    display: inline-block;

    background: #f9f9f9;
    border: 1px solid #f9f9f9;

    border-radius: 16px;

    padding: 4px 8px;
    margin-right: 8px;

    @apply --fontSizeXS;

    transition: 0.35s;

    &:hover {
        cursor: pointer;
    }

    & > span {
        display: inline-block;
        margin-right: 8px;
    }
}

.availabilityBadge {
    display: flex;
    align-items: center;

    font-weight: 400;
}

.editAllowed {
    &:hover {
        border: 1px solid #1a2b49;
    }
}

.availabilityStatus {
    width: 18px;
    height: 18px;

    display: inline-block;
    margin-right: 8px;
    border-radius: 50%;

    flex-shrink: 0;
}

.confirmed {
    background-color: #1e9f55;
}
.notConfirmed {
    border: 4px solid #1e9f55;
}
.notAvailable {
    border: 4px solid #ff0000;
}
