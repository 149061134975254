@import '../../marketplace.css';

:root {
    --root {
        max-width: 1152px;
        margin: 0 auto;
    }

    --layout {
        overflow: hidden;
        position: relative;

        & > img,
        & > svg {
            position: absolute;
            &:first-of-type {
                top: 100px;
            }
        }
    }

    --container {
        padding: 24px 24px 40px 24px;

        @media (--viewportMedium) {
            padding: 64px 24px 80px 24px;
        }
    }

    --link {
        @apply --fontSizeMM;
        font-weight: 600;

        display: inline-block;
        padding: 16px;

        border-radius: 4px;

        &:hover {
            cursor: pointer;
            text-decoration: initial;
        }
    }

    --rounded_image {
        position: relative;
        z-index: 1;

        border-radius: 50%;
        object-fit: cover;
        border: 4px solid #fff;

        &:hover {
            cursor: pointer;
        }
    }

    --subPages_root {
        max-width: 760px;
        text-align: center;
        margin-bottom: 64px;
    }

    --subPages_description {
        white-space: normal;
        @media (--viewportMedium) {
            white-space: break-spaces;
        }
    }

    --subPages_grid {
        display: flex;
        flex-direction: column;
        row-gap: 32px;

        margin: 32px 0 64px 0;

        @media (--viewportMedium) {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 24px;
            margin: 64px 0;
        }
    }

    --subPages_grid_item {
        flex-basis: calc(33.33% - 16px);

        & h2 {
            @apply --fontSizeXL;
            font-weight: 700;
            margin: 16px 0 8px 0;
        }

        & p {
            margin: 0;
        }

        & img,
        & svg {
            width: 80px;
            height: 80px;

            & path {
                fill: #8f2593;
            }
        }

        @media (--viewportMediumMax) {
            & img,
            & svg {
                width: 48px;
                height: 48px;
            }
        }

        @media (--viewportMedium) {
            & h2 {
                @apply --subPages_description;
            }
        }
    }

    --tab {
        @apply --fontSizeXL;
        font-weight: 700;

        padding: 8px;

        &:hover {
            cursor: pointer;
        }
    }

    --tab_selected {
        color: #8f2593;
        border-bottom: 3px solid #8f2593;
    }

    --tabs_navigation {
        display: flex;
        justify-content: center;
        column-gap: 32px;
        margin-top: 24px;
        margin-bottom: 32px;
    }

    --offer_block {
        max-width: 564px;
        width: calc(50% - 24px);

        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        @media (--viewportMediumMax) {
            width: 100%;
        }

        & > section,
        & > footer {
            padding: 24px;
        }

        & > footer {
            border-top: 1px solid #e6e6e6;
        }
    }

    --offer_logo {
        width: 100%;
        height: 160px;

        background-size: cover;
        background-position: center;
        background-color: #e6e6e6;
        border-radius: 8px;
    }
}

.root {
    @apply --root;
}

.subHeading {
    @apply --fontSizeSM;

    @media (--viewportMedium) {
        @apply --fontSizeXL;
    }

    font-weight: 700;
    color: #8f2593;
    text-align: center;

    margin: 0 0 16px 0;
}

.mainHeading {
    @apply --fontSizeXXL;
    text-align: center;
    margin: 0 0 16px 0;

    @media (--viewportMedium) {
        font-size: 48px;
        line-height: 64px;
    }
}

.heading {
    @apply --fontSizeXL;
    font-weight: 700;
    text-align: center;
    margin: 0 0 16px 0;

    & + p {
        margin-bottom: 24px;

        @media (--viewportMedium) {
            margin-bottom: 32px;
        }
    }

    @media (--viewportMedium) {
        font-size: 40px;
        line-height: 48px; /* 120% */
        letter-spacing: -0.5px;
    }
}

.description {
    margin-bottom: 24px;
    text-align: center;
}
/* pseudo links */
.subLinkPrimary {
    @apply --link;
    color: #fff;

    background-color: #8f2593;

    border: none;

    &:hover {
        background-color: var(--marketplaceColorDark);
    }
}

.subLinkSecondary {
    @apply --link;
    color: #1a2b49;
    text-decoration: underline;

    transition: 0.25;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.subLinkOutlined {
    @apply --link;
    color: #1a2b49;
    border: 1px solid #e6e6e6;

    transition: 0.35s;

    &:hover {
        border: 1px solid #1a2b49;
    }
}

.footer {
    display: flex;
    justify-content: center;
}

.sliderRoot {
    width: 100%;
    max-width: 100%;
}

.benefitHolder {
    display: flex;
    margin: 64px auto;
    justify-content: space-between;
    column-gap: 24px;

    @media (--viewportMediumMax) {
        max-width: 760px;
        margin: 32px auto;
        flex-direction: column;
        /* align-items: center; */
        row-gap: 24px;
        column-gap: unset;
    }
}

.benefitItem {
    padding: 32px;
    border-radius: 24px;
    border: 1px solid #e6e6e6;
    background: #fff;
    max-width: 368px;

    @media (--viewportMediumMax) {
        max-width: unset;
    }

    & p:first-of-type {
        margin-bottom: 8px;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: -0.5px;
        color: #8f2593;
    }

    & p:last-of-type {
        margin-bottom: 0;
        @apply --fontSizeMM;
    }
}

.assetHolder {
    border-radius: 16px;
    height: 312px;
    margin: 32px 0;

    background-size: cover;
    background-position: center;

    @media (--viewportMedium) {
        height: 364px;
        margin: 64px 0;
    }
}

.videoContentContainer {
    margin: 32px 0 64px 0;

    & > * {
        flex: 1;
    }

    & h3 {
        margin-top: 0;
    }

    @media (--viewportLarge) {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 24px;
    }
}

.videoHolder {
    overflow: hidden;
    border-radius: 16px;

    @media (--viewportMediumMax) {
        margin-bottom: 24px;
    }

    & video {
        height: 100%;
        width: 100%;
    }
}
