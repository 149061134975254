@import '../../marketplace.css';
@import './common.css';

.layout {
    min-height: unset;

    @apply --scrollBarStyled;
}

.root {
    max-width: 100vw;
    /* when page is empty, e.g. after filters are applied and for results are found */
    min-height: 70vh;

    transition: 0.25s;
    display: flex;

    @media (--viewportMediumMax) {
        min-height: calc(100vh - 72px);
        flex-wrap: nowrap;

        /* TransactionPanel is visble for mob by default */

        & > * {
            flex-shrink: 0;
        }
    }
}
.inboxContent,
.sectionContainerRootConfirmed {
    @apply --scrollBarStyled;
}

.transactionContent {
    width: 100vw;

    @media (--viewportLarge) {
        width: calc(64vw - 330px);
    }
}

.transactionContentExpandedDesktop {
    @media (--viewportLarge) {
        width: 64vw;
    }
}

.inboxContent {
    width: calc(100vw - 24px);
    position: relative;

    @media (--viewportLarge) {
        overflow-y: scroll;
        min-width: 400px;
        width: 36vw;
        border-right: 1px solid #e6e6e6;
    }

    & h1,
    & h3 {
        margin: 0;

        @apply --fontSizeXL;
        font-weight: 700;

        @media (--viewportMediumMax) {
            width: 100%;
            margin-bottom: 8px;
        }
    }
}

.asideContent {
    max-width: 100vw;

    @media (--viewportLarge) {
        width: 330px;
    }

    @media (--viewportMediumMax) {
        width: 100vw;
    }
}

.contentHidden {
    @media (--viewportMediumMax) {
        display: none;
    }
}

.contentVisible {
    @media (--viewportMediumMax) {
        max-width: 100vw;
        width: 100vw;
        overflow: auto;
    }
}

.navSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-height: 65px;

    border-bottom: 1px solid #e6e6e6;
    padding: 16px 24px;

    & button {
        @apply --navButton;
    }

    & h1,
    & h3,
    & h2 {
        @apply --fontSizeXL;
        font-weight: 700;
    }

    & svg {
        fill: none;
    }

    @media (--viewportMediumMax) {
        flex-wrap: wrap;

        padding: 14px;
        max-height: none;

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: white;
        /** more than main topbar */
        z-index: 56;

        & h2,
        & h3 {
            margin: 0;
        }
    }
}

.loading {
    margin-left: 24px;
    margin-right: 24px;
}

.footer {
    display: none;
}

.sectionContainerRoot {
    max-height: calc(100vh - 65px);
    @apply --scrollBarStyled;
    /*
   72px - topbar
   65px - navSection
   52px - email not confirmed notification
   */
    @media (--viewportLarge) {
        max-height: calc(100vh - 72px - 65px - 52px);
        overflow: auto;
    }
}

.sectionContainerRootConfirmed {
    @media (--viewportLarge) {
        max-height: calc(100vh - 72px - 65px);
    }
}

.staticTopBar {
    @media (--viewportMediumMax) {
        position: static;
    }
}

.infoNotAvailable {
    @apply --paragraphAntiSM;
    padding: 16px 24px 12px 24px;
}
