@import '../../marketplace.css';

.sidenote {
    @apply --paragraphAntiSM;
    text-align: center;

    @media (--viewportMedium) {
        max-width: 281px;
        margin: 0 auto 16px auto;
    }
}

.alert {
    margin-bottom: 44px;

    @media (--viewportSmallMax) {
        & > svg {
            display: none;
        }
    }
}

.field {
    & div {
        font-size: 16px;
        line-height: 24px;
    }
}
