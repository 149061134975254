@import '../../../marketplace.css';

:root {
    --actionBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 48px);

        color: #fff;
        z-index: 6;

        cursor: initial;

        border-radius: var(--borderRadius);
        margin: 16px 24px 0 24px;

        @media (--viewportMedium) {
            max-width: 1150px;
            margin: 32px 0 0 0;
        }
    }
    --sticky {
        position: sticky;
        top: 76px;

        @media (--viewportMedium) {
            top: 96px;
        }
    }
}

.ownListingText {
    @apply --fontSizeSM;
    font-weight: 400;
    margin: 0;
}

.actionBar {
    @apply --sticky;
    @apply --actionBar;

    background-color: var(--matterColor);
    padding: 16px;
    margin: 0 auto;

    top: 104px;

    @media (--viewportMediumMax) {
        top: 76px;
    }
}

.ownListingTextPendingApproval,
.actionBarClosed {
    background-color: #fa0;
}

.actionBarEmailUnverified {
    top: 188px;

    @media (--viewportLarge) {
        top: 164px;
    }
}

.editListingLink {
    @apply --marketplaceH4FontStyles;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0;

    color: #fff;

    transition: var(--transitionStyleButton);

    &:hover {
        color: var(--matterColorLight);
        text-decoration: none;
    }
}

.editIcon {
    margin-right: 8px;
}
