@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    --Topbar_logoHeight: 25px;
}

.root {
    width: 100%;

    /* Top Bar's drop shadow should always be visible */
    z-index: var(--zIndexTopbar);
    /*position: relative;*/
}

.container {
    /* Size */
    width: 100%;
    height: var(--topbarHeight);

    /* Layout for child components */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* fill */
    background-color: var(--matterColorLight);

    /* shadows */
    box-shadow: var(--boxShadow);

    @media (--viewportLarge) {
        display: none;
    }
    @media (--viewportMiniSmallMax) {
        overflow: hidden;
    }
}

.logoutButton {
    margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);

    /* Layout */
    display: flex;
    width: auto;
    margin: 0;
    border-width: 0px;
    background-color: transparent;

    /* Hovers */
    &:enabled {
        cursor: pointer;
    }
    &:enabled:hover,
    &:enabled:active {
        background-color: transparent;
        box-shadow: none;
    }
    &:disabled {
        background-color: transparent;
        cursor: auto;
    }
}

.menu {
    padding: 0 0 0 16px;
    position: relative;
    /* to align logo horizontally  */
    margin-right: 6vw;
}
.searchMenu {
    padding: 0px;
}
.menuIcon {
    fill: none;
}
.menuIcon,
.searchMenuIcon {
    margin: 9px 0;
}
.searchMenuIcon {
    width: 24px;
    height: 24px;
}

.notificationDot {
    @apply --notificationDot;

    top: 7px;
    right: 0px;
}

.desktop {
    display: none;

    @media (--viewportLarge) {
        display: block;
    }
}

.rootSearchIcon {
    stroke: var(--matterColor);
    fill: none;
}

/* ================ Modal ================ */

.modalContainer {
    @apply --marketplaceModalInMobileBaseStyles;
    padding: 0;

    @media (--viewportMedium) {
        flex-basis: 576px;
    }
}

.modalContainerMobile {
    @apply --marketplaceModalBaseStylesCore;

    min-height: 100vh;
    padding: 0;
    border-radius: 0;
}

.missingInformationModal {
    @apply --marketplaceModalBaseStyles;
    padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
    @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
    @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
    @apply --marketplaceModalHighlightEmail;
}

.helperLink {
    @apply --marketplaceModalHelperLink;
}

.helperText {
    @apply --marketplaceModalHelperText;
}

.error {
    @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
    @apply --marketplaceModalBottomWrapper;

    /* Align the helper links to the left since there isn't a Primary Button anymore */
    text-align: left;
}

.modalIcon {
    @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
    @apply --marketplaceButtonStylesPrimary;
}

.genericError {
    position: fixed;
    top: calc(var(--topbarHeight) + 145px);

    /* Place enough to the right to hide the error */
    right: -355px;
    opacity: 0;

    max-width: 340px;
    margin: 0 15px;

    /* Animate error from the right */
    transition: all ease-out 0.2s;
    transition-delay: 1s;

    /* Bleed the bg color from the bottom */
    background-color: #ff4c38;
    border-radius: 4px;

    /* Place on top of everything */
    z-index: var(--zIndexGenericError);

    @media (--viewportMedium) {
        top: calc(var(--topbarHeightDesktop) + 145px);
    }
}

.genericErrorVisible {
    right: 10px;
    opacity: 1;
}

.genericErrorContent {
    position: relative;
    padding: 16px 22px;
    border-radius: 4px;
    background-color: var(--errorColorLight);
    border-radius: 4px;
    /* close icon */
    & > code {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 0;

        padding: 0 12px;

        & svg {
            width: 12px;
            height: 12px;
            & path {
                fill: #fff;
            }
        }
    }
}

.genericErrorText {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightSemiBold);
    margin: 0;
    color: var(--matterColorLight);

    @media (--viewportMedium) {
        margin: 0;
    }
}

.topBarModalRoot {
    & > div {
        background-color: white !important;
    }
}

.topBarNotificationHolderMob {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding-right: 16px;

    & svg {
        width: 24px;
        height: 24px;
    }

    & > code {
        &:hover {
            cursor: pointer;
        }

        /* wisepops notification holder */

        & > div {
            top: 0px !important;
            right: 0px !important;
            width: 7px !important;
            height: 7px !important;
            & > span {
                display: none !important;
            }
        }
    }
}
