@import '../../marketplace.css';

.modalContent {
    height: 100%;
    padding-top: 56px;

    @media (--viewportMedium) {
        max-height: 720px;
    }

    & form {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.modalHeading {
    font-size: 32px;
    line-height: 40px;

    margin: 0 0 16px 0;
}

.modalDesc {
    margin-bottom: 32px;
}

.modalSumToPay {
    display: flex;
    justify-content: space-between;
    margin: 0;

    & h5 {
        color: var(--marketplaceColor);
        font-weight: 700;
        font-size: 32px;
        line-height: 27px;
    }
}

.modalLink {
    color: var(--marketplaceColor);
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.option {
    margin: 0;
}

.sidenote {
    @apply --paragraphAntiSM;
    text-align: center;
    margin-bottom: 16px;
}

.footer {
    margin-top: auto;
    @media (--viewportMedium) {
        margin-top: 24px;
    }
}

.field {
    border-radius: 4px !important;
    margin-bottom: 16px;
}

.footer {
    & > button:nth-of-type(2) {
        margin-top: 16px;

        border: none;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
