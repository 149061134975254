@import '../common.css';

.description,
.heading {
    @apply --subPages_description;
}

.grid {
    @apply --subPages_grid;
}

.gridItem {
    flex-basis: calc(33.33% - 16px);
}
