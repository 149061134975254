@import '../../marketplace.css';

:root {
    /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

    /* Compose text label items on top of this */
    /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
    --TopbarDesktop_label: {
        flex-shrink: 0;
        display: inline-block;
        text-decoration: inherit;

        color: var(--matterColor);

        &:hover {
            color: var(--matterColorDark);
            text-decoration: none;
        }
    }

    --TopbarDesktop_action_button: {
        flex-shrink: 0;
        font-weight: 600;
        line-height: 28px;

        background: #8f2593;
        padding: 6px 16px;

        border-radius: 32px;

        transition: 0.35s;

        &,
        &:hover {
            color: white;
            text-decoration: none;
        }

        &:hover {
            background: var(--marketplaceColorDark);
        }
    }

    --TopbarDesktop_badge: {
        @apply --fontSizeXS;

        padding: 4px 16px;

        border-radius: 48px;
    }

    --Topbar_flex_Y_centered {
        display: flex;
        align-items: center;
    }
}

/* Desktop */
.root {
    /* Size */
    width: 100%;
    min-height: var(--topbarHeightDesktop);
    /* Layout for child components */
    @apply --Topbar_flex_Y_centered;
    justify-content: space-between;
    padding: 12px 24px;

    /* fill */
    background-color: var(--matterColorLight);

    /* shadows */
    box-shadow: var(--boxShadowLight);

    position: relative;
}

/* logo */
.logoLink {
    flex-shrink: 0;
}

.logo {
    /* If height is increased, change link paddings accordingly */
    height: 40px;

    /* Good aspect ratio should be less than 160/27 (width/height) */
    /* NOTE: extremely wide logos, need special handling. */
    width: auto;
    max-width: 220px;
    object-fit: contain;
    object-position: left center;
}

/* Search */
.searchLink {
    flex-grow: 1;
    height: 100%;
    margin-right: 24px;

    @media (--viewportLarge) {
        padding-left: 0;
    }
}

.search {
    @apply --marketplaceH4FontStyles;
    @apply --TopbarDesktop_label;
    color: var(--matterColor);
}

.notificationDot {
    @apply --notificationDot;
}

/* Profile menu */
.profileMenuLabel {
    flex-shrink: 0;

    @apply --Topbar_flex_Y_centered;

    border: 1px solid #e6e6e6;
    border-radius: 24px;

    padding: 8px;

    transition: 0.35s;

    &:active {
        border-bottom: 1px solid #1a2b49;
    }

    &:hover {
        cursor: pointer;
        border: 1px solid #1a2b49;
    }
}

.profileMenuLabelLoggedOut {
    column-gap: 8px;

    & > code {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-color: #b2b2b2;
        border-radius: 50%;
    }
}

.avatar {
    display: flex;
    width: 32px;
    height: 32px;

    margin-left: 8px;
}

.profileMenuContent {
    width: 296px;

    top: calc(100% + 4px);
    border-radius: 16px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    @apply --scrollBarStyled;
    /* to avoid scrollbar protruding from rounded border */
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    @media (--viewportMedium) {
        max-height: calc(100vh - 72px);
        overflow: auto;

        &.emailNotVerified {
            max-height: calc(100vh - 72px - 60px);
        }
    }
}

.inboxLink,
.signupLink,
.loginLink {
    @apply --TopbarDesktop_label;
    @apply --fontSizeMM;

    position: relative;
}

.signupLink,
.createListingLink {
    @apply --TopbarDesktop_action_button;
}

.loginLink {
    flex-shrink: 0;
    height: 100%;

    color: var(--matterColor);

    &:hover {
        color: var(--matterColorDark);
    }
}

.topBarLocationForm {
    min-width: 296px;
    margin-left: 4vw;
}

.topBarMainNavigationHolder {
    @apply --Topbar_flex_Y_centered;
}

.topBarAsideNavigationHolder {
    @apply --Topbar_flex_Y_centered;

    column-gap: 24px;
}

.topBarNotificationBadge {
    &,
    & code {
        display: flex;
    }

    & code {
        /* wisepops notification holder */

        & > div {
            top: -6px !important;
            right: -6px !important;
            width: 7px !important;
            height: 7px !important;
            & > span {
                display: none !important;
            }
        }
    }

    border: 1px solid #e6e6e6;
    padding: 8px;
    border-radius: 50%;

    position: relative;

    transition: 0.35s;

    &:hover {
        border: 1px solid #1a2b49;
    }

    & .notificationDot {
        right: 4px;
    }
}

.findListingsLink {
    color: #1a2b49;
    line-height: 24px;
    margin-left: 24px;
    padding: 0;

    position: relative;

    &.firstLink {
        margin-left: 60px;
    }

    & code {
        display: none;
        position: absolute;

        left: 0;
        right: 0;
        top: 46px;
    }

    &.selected {
        color: #8f2593;

        & code {
            display: block;
            background-color: #8f2593;
            height: 3px;
        }
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}
