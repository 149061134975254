@import '../../marketplace.css';
@import './common.css';

.pagination {
    margin: auto 24px 0 24px;
}

.itemList {
    margin: 0 0 0 24px;
    padding: 0 0 32px 0;

    /*
        * auto or scrolls values for overflow property
        * break InboxPanel ui when InboxItem ControlsPanel is opened
        */
    overflow: visible;
}

.buttonsHolder {
    display: flex;
    flex-shrink: 0;

    & > button:first-of-type {
        margin-right: 16px;
    }

    & svg {
        margin-right: 4px;
    }

    @media (--viewportMediumMax) {
        width: 100%;
        justify-content: space-between;
    }
}

.buttonsHolderMob {
    position: sticky;
    top: 0;
    z-index: 2;

    background: white;
    padding: 24px;

    & > button {
        @apply --navButton;
    }
}

.buttonsHolderScrolled {
    box-shadow: 0px 4px 6px -1px #0000000d;
}

.badgeSelected {
    color: #8f2593;
    background-color: #f9f4fa;

    white-space: pre;

    border: 2px solid #8f2593;

    & path {
        stroke: #8f2593;
    }

    &:hover,
    &:focus {
        border: 2px solid #8f2593;
        background-color: #f9f4fa;
    }
}

.focused {
    border-color: #1a2b49;
}

.error {
    @apply --paragraphAntiSM;
    text-align: center;
    margin-top: 16px;
}

.mobileHeading {
    padding: 24px 24px 0 24px;
}
