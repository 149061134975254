@import '../../../marketplace.css';

.listItem {
    transition: 0.35s;
    background-color: #fff;
    position: relative;

    &:hover {
        cursor: pointer;
        background-color: #f2f2f2;
    }
}

.roundedBadge {
    @apply --roundedBadgeWithIconInsideS;
    pointer-events: none;
    bottom: 10px;
    left: 18px;
    top: unset;

    pointer-events: none;

    & svg {
        width: 16px;
        height: 16px;
    }

    @media (--viewportMediumMax) {
        bottom: 28px;
    }
}

.isSelected {
    border-left: 4px solid var(--marketplaceColor);
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;

    padding: 16px 0;
}

.itemLink {
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;

    @media (--viewportMediumMax) {
        flex-wrap: wrap;
        padding-right: 8px;
    }

    /* Layout */
    display: flex;
    align-items: center;
    /* Remove text underline from this link */
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.itemAvatar {
    cursor: pointer;

    margin: 0 8px 0 8px;

    & > div {
        width: 48px;
        height: 48px;

        border: unset;
    }
}

.itemInfo {
    /* inboxContent - avatar width - it's margin - itemList margin - (not approx stateName width )- archive panel + margin */
    max-width: calc(100vw - 48px - 8px - 24px - 32px - 16px - 18px);
    font-weight: var(--fontWeightSemiBold);

    margin: 0 auto 0 0;

    color: var(--matterColor);

    @media (--viewportLarge) {
        /* 36vw- inboxContent
    48px avatar width 
    8px avatar's margin 
    24px - itemList margin 
    120px approx stateName width 
    32px archive panel
    16px- archive panel's margin 
    18px extra space
    */
        max-width: calc(36vw - 48px - 8px - 24px - 120px - 32px - 16px - 18px);
    }
}

.itemInfoMob {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-bottom: 4px;
}

.itemUsername {
    display: flex;
    align-items: center;
    column-gap: 8px;
    /* Font */
    color: var(--matterColor);
    font-weight: 600;

    margin: 0;

    @media (--viewportMediumMax) {
        & .newActionBadge {
            display: none;
        }
    }
}

.bookingInfoWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    font-size: 14px;
    line-height: 14px;
    margin-top: 2px;
    padding-top: 2px;

    @media (--viewportMedium) {
        padding-top: 0px;
        margin-top: 8px;
        line-height: 16px;
    }
}

.itemPrice {
    &::before {
        font-size: 10px;
        margin: 0 6px;
        display: inline-block;
        content: '\25CF'; /* middot */
    }
}

.itemState {
    /* Font */
    @apply --marketplaceH5FontStyles;

    font-weight: var(--fontWeightMedium);
    margin-top: 6px;
    margin-bottom: 0px;

    text-align: right;

    flex-shrink: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.itemStateMob {
    width: 100%;
    margin-top: 8px;
    display: flex;
}

.lastTransitionedAt {
    @apply --fontSizeSM;
    text-align: center;
    color: #b2b2b2;
    margin: 0;
}

.pagination {
    margin: auto 24px 0 24px;
}

.stateName {
    display: inline-block;
    @apply --fontSizeSM;
    white-space: nowrap;

    padding: 2px 16px;
    margin-right: auto;

    border-radius: 24px;
    border: 1px solid #fff;

    @media (--viewportLarge) {
        margin-bottom: 4px;
        margin-right: unset;
    }
}

.stateActionNeeded {
    text-align: right;
    color: var(--attentionColor);
    background-color: #fff7f0;
}

.stateExpired {
    color: var(--matterColorAnti);
    text-align: right;
    background-color: #e6e6e6;
}

.stateFailed {
    color: var(--failColor);
    text-align: right;
    background-color: #fff0f0;
}

.stateSucces {
    color: var(--successColor);
    text-align: right;
    background-color: #f0fff6;
}

.nameEmphasized {
    font-weight: var(--fontWeightBold);

    /* baseline alignment fixes */
    margin-top: -1px;
    margin-bottom: 1px;
}

.bookingNoActionNeeded {
    color: var(--matterColorAnti);
    text-overflow: ellipsis;
    overflow: hidden;

    margin: 0;

    max-width: 100%;
    white-space: nowrap;
}

.bookingActionNeeded {
    margin-bottom: 0;
    font-weight: 600;
    color: #1a2b49;
}

.newActionBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;

    color: var(--marketplaceColor);
    background-color: var(--marketplaceColorFade);
    border-radius: 24px;
    border: 1px solid #fff;

    margin-right: 8px;
    margin-bottom: 0;

    /* padding: 2px 16px; */

    @apply --fontSizeSM;
    font-weight: 400;

    @media (--viewportLarge) {
        margin-left: 8px;
        margin-right: 0;
    }
}

.bannedUserLink {
    & .itemUsername,
    &:hover .itemUsername,
    & .bookingInfo,
    & .stateName,
    & .lastTransitionedAt {
        color: var(--matterColorAnti);
    }
}

.ellipsis {
    display: inline-block;
    width: 32px;
    height: 32px;

    font-size: 32px;
    line-height: 10px;
    letter-spacing: -1.5px;

    padding-right: 2px;

    overflow: hidden;
    text-align: center;

    color: #1a2b49;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    background: white;

    &:hover {
        cursor: pointer;
    }

    @media (--viewportLarge) {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.controls {
    padding: 8px 0;
    border-radius: 8px;
    min-width: unset;
    width: 134px;

    bottom: unset;
    top: calc(100% + 4px);
    right: 0;
    left: unset;

    & li {
        pointer-events: visible;
        padding: 8px 16px;
        @apply --fontSizeSM;

        &:hover {
            background-color: #f2f2f2;
            cursor: pointer;
        }
    }

    &::after {
        display: none;
    }
}

.isArchived {
    width: 154px;
}

.tooltipWrapper {
    &::after {
        display: none;
    }
}

.archieveLink {
    display: flex;
    align-items: center;
}
