@import '../../../marketplace.css';

.extraPackagesSection {
    @apply --flexCentered;

    flex-direction: column;

    @media (--viewportSmallMax) {
        margin-bottom: 147px;
    }

    & footer {
        display: flex;
        flex-direction: column;
        column-gap: 8px;
        row-gap: 8px;
        margin-top: auto;

        @media (--viewportMedium) {
            column-gap: 32px;
        }
    }
}

.extraPackagesWrapper {
    display: flex;
    flex-direction: column;

    width: 100%;

    margin-bottom: 24px;
    padding: 16px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 3px rgb(0 0 0 / 5%);
}

.extraPackagesUpperSection,
.extraPackagesUpperSectionMob {
    justify-content: space-between;

    margin-bottom: 16px;

    @apply --fontSizeM;

    font-weight: 700;

    & h4 {
        margin: 0;
        color: #1a2b49;
        letter-spacing: -0.25px;
    }

    & aside {
        color: var(--marketplaceColor);
    }

    & * {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

.extraPackagesUpperSectionMob {
    display: flex;

    & h4 {
        margin-bottom: 8px;
    }

    @media (--viewportMedium) {
        display: none;
    }
}

.extraPackagesUpperSection {
    display: none;

    @media (--viewportMedium) {
        display: flex;
    }

    & aside {
        display: flex;
    }
}

.extraPackagesPrice {
    margin-right: 16px;
}

.extraPackagesDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 24px 0;
}

.extraPackagesFooterWrapper {
    display: flex;
    align-items: center;

    & svg {
        margin-right: 8px;
        color: transparent;
    }

    & span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}

.extraPackagesFooterHeading {
    font-weight: 700;
    font-size: 14px;
    line-height: 8px;
    margin: 0;

    & span {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

.proceedWithNoPackageButton {
    width: 100%;

    margin: 12px 0 96px 0;
}

.proceedWithPackageButton {
    &:last-of-type {
        width: 100%;
        margin: 12px 0 96px 0;
    }
}

.proceedWithFooter {
    width: 100%;

    @media (--viewportSmallMax) {
        display: flex;
        justify-content: center;

        background: white;
        border-top: 1px solid #e6e6e6;

        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;

        padding: 12px 24px;
        width: auto;

        & .proceedWithPackageButton,
        & .proceedWithNoPackageButton {
            margin: 0;
        }
    }
}
