@import '../../marketplace.css';

.root {
    color: #1a2b49;

    min-width: 117px;
    @apply --fontSizeSM;
    padding: 8px 16px;
    border: 2px solid #1a2b49;
    border-radius: 48px;

    display: flex;

    & > svg {
        flex-shrink: 0;
        margin-right: 8px;
    }

    &:hover {
        cursor: pointer;
    }
}

.rootMob {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    border: 2px solid #1a2b49;

    width: 32px;
    height: 32px;

    & svg {
        margin: 0;
    }

    &:hover {
        cursor: pointer;
    }
}

.overlay {
    position: relative;
}

.selected {
    background-color: #f9f4fa;
    border: 2px solid #8f2593;

    & > span {
        color: #8f2593;
    }

    & > svg {
        & path {
            stroke: #8f2593;
        }
    }
}

.popup {
    width: 280px;

    position: absolute;
    right: 0;
    z-index: 6;

    margin-top: 4px;
    padding: 16px 0;

    background: white;
    border-radius: 16px;
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);

    & input:checked {
        & + label:hover {
            background-color: #f2f2f2;
        }
    }

    & label {
        width: 100%;
        padding: 8px 16px;

        display: flex;
        align-items: center;

        & span {
            line-height: normal;
        }
    }
}

.modalContainer {
    border: none;
}

.radioField {
    &,
    &:hover,
    &:last-of-type {
        border-radius: 0;
        border: none;
        background-color: unset;

        & input:checked + label {
            background-color: unset;
        }
    }
}

.modalRadioRoot {
    @media (--viewportSmallMax) {
        margin: 0 -24px;
    }
}

.modalContent {
    &,
    & form {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    & form {
        & label {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 8px 16px;

            @media (--viewportSmallMax) {
                padding: 8px 24px;
            }
        }

        & input:checked {
            & + label:hover {
                background-color: #f2f2f2;
            }
        }
    }
}

.modalHeading {
    @apply --fontSizeXL;
    font-weight: 700;

    margin: 16px 0;
}

.modalDesc {
    margin: 0 0 16px 0;

    @apply --fontSizeSM;
}

.modalAction {
    margin-top: auto;
}

.modalHeading,
.modalDesc,
.modalAction {
    @media (--viewportMedium) {
        display: none;
    }
}
