@import '../../common.css';

.root {
    margin-bottom: 64px;
}

.description,
.heading {
    @apply --subPages_description;
}

.tabsNavigation {
    display: flex;
    column-gap: 8px;

    margin: 8px auto 32px auto;
    justify-content: center;

    & .tab {
        @apply --fontSizeSM;
        padding: 8px 16px;
        border: 1px solid #e6e6e6;
        border-radius: 48px;

        &:hover {
            cursor: pointer;
        }

        &.tabSelected {
            color: #8f2593;
            border: 2px solid #8f2593;
            background: #f9f4fa;
        }
    }

    @media (--viewportMediumMax) {
        overflow-x: scroll;
        justify-content: flex-start;

        @apply --scrollBarStyled;

        & .tab {
            flex-shrink: 0;
        }
    }
}

.sidenote {
    color: #b2b2b2;
}

.tabsHeroHeading {
    text-align: left;
    margin-bottom: 32px;

    @apply --fontSizeXXXL;

    @media (--viewportMediumMax) {
        @apply --fontSizeXL;
        font-weight: 700;
    }
}

.blocksHolder {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 32px;

    margin-bottom: 24px;

    @media (--viewportMediumMax) {
        flex-direction: column;
    }
}
