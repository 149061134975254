@import '../../marketplace.css';

.sliderOuterContainer {
    position: relative;

    max-width: 766px;

    margin: 0 auto;

    @media (--viewportLarge) {
        /**
      * 360 - left column
      * 48 - left & right content padding
      * 24 - right marging for the left column
      */
        width: calc(100vw - 360px - 48px - 24px);
    }

    & [data-tooltip='item'] * {
        pointer-events: none;
    }

    @media (max-width: 480px) {
        & [data-tooltip='wrapper'] {
            flex-shrink: 0;
        }

        & [data-tooltip='info-wrapper'] {
            flex-wrap: nowrap;
        }
    }

    &:after {
        @media (--viewportLarge) {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 80.21%, #ffffff 100%);
            width: 100%;
            pointer-events: none;
        }
    }
}

.rightOverlayHidden {
    &:after {
        display: none;
    }
}

.carouselHolder {
    margin: 0;

    & *[role='option'] {
        &:hover {
            cursor: pointer;
        }
    }
}

.sliderWrapper {
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.sliderAnimation {
    transition-duration: 0.3s;
    padding-bottom: 32px;
}

.listingPageCard {
    display: block;
    margin-right: 16px;
    flex-basis: calc(100% - 30px);

    @media (--viewportLarge) {
        flex-basis: calc(33.33% - 30px);
        margin-right: 32px;
    }
}

.listingPageCardSingle {
    margin-right: 0;
}

.listingSimilarDiscipline {
    position: relative;

    & > button {
        left: 14px;
        top: 14px;

        @media (--viewportMedium) {
            left: 20px;
            top: 20px;
        }
    }

    & > div:first-of-type {
        right: 20px;
        top: 20px;
    }
}

.listingRefClassName {
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

.sliderButton {
    @apply --sliderButton;
    z-index: 1;
    position: absolute;

    &.hideOnStartEnd {
        &:disabled {
            @media (--viewportLarge) {
                display: none;
            }
        }
    }

    @media (--viewportLarge) {
        top: calc(50% - 31px);
        width: 64px;
        height: 64px;
    }
}

.sliderButtonBack {
    @apply --sliderButtonBack;
    left: 0;
}

.sliderButtonNext {
    @apply --sliderButtonNext;
    right: 0;
}

.singleItemsWrapper {
    @media (--viewportMedium) {
        display: flex;

        & > div {
            /* 64px left & right margins for non-single cards */
            flex-basis: calc(33.333% - 64px);
        }
    }
}

.paginationWrapper {
    bottom: -31px;

    & span[datatype='paginationDot'] {
        background: #b2b2b2;
        opacity: 0.5;
    }

    & span[datatype='paginationActiveDot'] {
        background: #b2b2b2;
    }
}
