@import '../../marketplace.css';

:root {
    --Topbar_badge: {
        @apply --fontSizeXS;

        padding: 4px 16px;

        border-radius: 48px;
    }
}

.topBarMenuItem {
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    padding: 8px 16px;
    transition: 0.35s;

    & a {
        width: 100%;

        & p {
            position: relative;
            margin: 0;
            display: inline-block;
        }
    }

    &:hover {
        background-color: #f2f2f2;
        cursor: pointer;
    }

    &.topBarMenuItemFirst {
        @media (--viewportLarge) {
            margin-top: 10px;
        }
    }

    &.topBarMenuItemLast {
        @media (--viewportLarge) {
            margin-bottom: 10px;
        }
    }
}

.currentPage {
    &,
    & a {
        color: var(--marketplaceColor);
    }

    &:hover,
    & a:hover {
        cursor: auto;
        background-color: unset;
        color: var(--marketplaceColor);
    }
}

.topBarMenuSubLabel,
.topBarMenuItemDisabled,
.topBarMenuAvailability {
    &:hover {
        background-color: unset;
    }
}

.topBarMenuSubLabel {
    padding-top: 16px;
    margin-top: 16px;

    border-top: 1px solid #e6e6e6;

    & p {
        @apply --fontSizeXS;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.topBarMenuAvailabilityHolder {
    display: inline-block;

    & .topBarAvailabilityBadge {
        margin: 0;
        background: none;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }

    & .availability-notAvailable {
        color: #f00;
    }

    & .availability-confirmed,
    & .availability-notConfirmed {
        color: #1a2b49;
    }
}

.topBarMenuAvailability {
    padding: 16px;
    padding-bottom: 0;
}

.topBarAvailabilityBadge {
    flex-wrap: nowrap;
    white-space: nowrap;

    width: auto;
}

.topBarMenuFirstSubLabel {
    border-top: none;
    margin-top: 0;

    @media (--viewportLarge) {
        padding-top: 6px;
    }
}

.profileSettingsLink,
.topBarMenuLink {
    &,
    & p {
        @apply --fontSizeMM;
    }

    position: relative;

    color: var(--matterColor);
    transition: var(--transitionStyleButton);

    & .notificationDot {
        right: -7px;
    }

    &:hover {
        color: var(--matterColorDark);
        text-decoration: none;
    }
}

.topBarMenuItemDisabled {
    & * {
        color: #b2b2b2;
    }
    &:hover {
        cursor: not-allowed;
    }
}

.tobBarMenuFooter {
    padding: 16px 16px 6px 16px;
    margin-top: 8px;

    border-top: 1px solid #e6e6e6;

    @media (--viewportMediumMax) {
        padding: 12px 24px !important;
    }
}

.topBarUpgradeBadge,
.topBarUnreadMessageBadge {
    @apply --Topbar_badge;
}

.topBarUpgradeBadge {
    background-color: #f9f4fa;

    & > span {
        color: var(--marketplaceColor);
    }
}

.topBarUnreadMessageBadge {
    color: #1a2b49;
    background: #f9f9f9;
    flex-shrink: 0;
}

.topBarNewMenuItem {
    background: #f9f4fa;
    color: var(--marketplaceColor);
}

.logoutButton {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 18px;

    @apply --fontSizeMM;
    color: #1a2b49;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    transition: var(--transitionStyleButton);
    border: 1px solid #e6e6e6;
    border-radius: 4px;

    transition: 0.35s;

    &:hover {
        text-decoration: none;
        cursor: pointer;
        border: 1px solid #1a2b49;
    }
}

.desktopOnly {
    @media (--viewportMediumMax) {
        display: none;
    }
}

.mobileOnly {
    @media (--viewportLarge) {
        display: none;
    }
}

.notificationDot {
    @apply --notificationDot;
}

.divider {
    height: 0.5px;
    background-color: #e6e6e6;
    margin: 16px 0;
}
