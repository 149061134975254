@import '../../marketplace.css';

.completeProfileWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.completeProfileHeader {
    margin-top: 16px;
    margin-bottom: 16px;

    letter-spacing: -0.5px;
    font-weight: 700;

    font-size: 32px;
    line-height: 40px;

    & h1,
    & h3 {
        margin-top: 0;
    }
}

.completeProfileProtectionMessage {
    @apply --paragraphAntiSM;
    @apply --flexCentered;
    column-gap: 4px;
}

.link {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.modalContainer {
    flex-basis: 480px;

    border-top: none;
}

.modalFormBody {
    & label {
        font-size: 17px;
        line-height: 20px;
    }
}

.modalFormBodyFullHeight {
    flex: 1;

    & form {
        height: 100%;
        display: flex;
        flex-direction: column;

        & footer {
            margin-top: auto;
        }
    }
}

.error {
    @apply --paragraphAntiSM;
    color: red;

    position: absolute;
    top: calc(100% + 12px);
    text-align: left;
}

.modalContent {
    height: 100%;
}

.footerOverlay {
    @media (--viewportSmallMax) {
        position: fixed;
        bottom: 24px;
        left: 24px;
        right: 24px;
    }
}

.completeProfileWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > svg {
        flex-shrink: 0;
    }
}
